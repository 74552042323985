import { Modal, Form, Row, Col, Select, Tabs, Button, Checkbox } from "antd";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../context/DataContext";
import { TabsProps } from "antd/lib";
import { FileDoneOutlined } from "@ant-design/icons";
import AuthContext from "../../context/AuthContext";
import { UserProps } from "../../interfaces/interfaces";
import { PREFERENCES_INITIAL_STATE } from "../../utils/data";
import { useDemoModal } from "../../hooks";
import { useNavigate } from "react-router-dom";
const { Option } = Select;

interface Props {
  open: boolean;
  onCancel: () => void;
}

const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

const summaryOptions = [
  {
    label: "Client name",
    value: "_id",
  },
  {
    label: "Current A/R",
    value: "current",
  },
  {
    label: "1-30 Days",
    value: "1-30",
  },
  {
    label: "31-60 Days",
    value: "31-60",
  },
  {
    label: "61-90 Days",
    value: "61-90",
  },
  {
    label: ">90 Days",
    value: ">90",
  },
  {
    label: "Total A/R",
    value: "total",
  },
];

const detailOptions = [
  {
    label: "Created at",
    value: "1",
  },
  {
    label: "Client name",
    value: "2",
  },
  {
    label: "Transaction Id",
    value: "3",
  },
  {
    label: "Transaction Type",
    value: "4",
  },
  {
    label: "Terms",
    value: "5",
  },
  {
    label: "Due Date",
    value: "6",
  },
  {
    label: "Balance",
    value: "7",
  },
  {
    label: "Status",
    value: "8",
  },
  {
    label: "Last Contact",
    value: "9",
  },
  {
    label: "Last Note",
    value: "11",
  },
  {
    label: "Last note Author",
    value: "12",
  },
];

const orderOptions = [
  { label: "Ascending", value: "ascend" },
  { label: "Descending", value: "descend" },
];

const columnsSummaryOptions = [
  { label: "Current", value: "Current", disabled: true },
  { label: "1-30", value: "1-30", disabled: true },
  { label: "31-60", value: "31-60", disabled: true },
  { label: "61-90", value: "61-90", disabled: true },
  { label: ">90", value: ">90", disabled: true },
  { label: "Unbilled Time", value: "unbilledTime" },
  { label: "Last Payment", value: "lastPayment" },
  { label: "Total", value: "Total", disabled: true },
];

const columnsDetailsOptions = [
  { label: "Transaction Id", value: "DocNumber", disabled: true },
  { label: "Transaction Type", value: "TransactionType", disabled: true },
  { label: "Terms", value: "SalesTermRef", disabled: true },
  { label: "Due Date", value: "DueDate", disabled: true },
  { label: "Balance", value: "Balance", disabled: true },
  { label: "Status", value: "Status", disabled: true },
  { label: "Collection Details", value: "CollectionDetails" },
];

export const ModalPreferences = ({ open, onCancel }: Props) => {
  const { handleEditData, dataLoading } = useContext(DataContext);
  const navigate = useNavigate();
  const { profileInfo, isDemoEnv } = useContext(AuthContext);
  const [hasChanged, setHasChanged] = useState(false);
  const { DemoModal, openModalDemo } = useDemoModal();
  const [newPreferences, setNewPreferences] = useState<
    UserProps["preferences"] | null
  >(PREFERENCES_INITIAL_STATE as any);

  useEffect(() => {
    if (profileInfo && profileInfo.preferences) {
      setNewPreferences(profileInfo?.preferences as UserProps["preferences"]);
    }
  }, [profileInfo]);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Summary",
      children: (
        <>
          <Form layout="horizontal">
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="Sort Default By:"
                  className="collections-form__form-item"
                >
                  <Select
                    showSearch
                    placeholder="Select a column to sort by"
                    optionFilterProp="children"
                    filterOption={filterOption}
                    value={
                      newPreferences &&
                      newPreferences?.collections?.summary &&
                      newPreferences?.collections?.summary?.defaultSortColumnKey
                        ?.name
                    }
                    onChange={(value) => {
                      setHasChanged(true);
                      setNewPreferences((prev) => {
                        if (prev)
                          return {
                            ...prev,
                            collections: {
                              ...prev.collections,
                              summary: {
                                ...prev.collections.summary,
                                defaultSortColumnKey: {
                                  ...prev.collections.summary
                                    .defaultSortColumnKey,
                                  name: value,
                                },
                              },
                            },
                          };
                      });
                    }}
                    options={summaryOptions}
                  />
                </Form.Item>
                <Form.Item
                  label="Sorting order:"
                  className="collections-form__form-item"
                >
                  <Select
                    showSearch
                    placeholder="Sorting order"
                    optionFilterProp="children"
                    filterOption={filterOption}
                    value={
                      newPreferences &&
                      newPreferences.collections?.summary &&
                      newPreferences?.collections?.summary?.defaultSortColumnKey
                        ?.order
                    }
                    onChange={(value) => {
                      setHasChanged(true);
                      setNewPreferences((prev) => {
                        if (prev)
                          return {
                            ...prev,
                            collections: {
                              ...prev.collections,
                              summary: {
                                ...prev.collections.summary,
                                defaultSortColumnKey: {
                                  ...prev.collections.summary
                                    .defaultSortColumnKey,
                                  order: value,
                                },
                              },
                            },
                          };
                      });
                    }}
                    options={orderOptions}
                  />
                </Form.Item>
                <Form.Item
                  label="Columns"
                  className="collections-form__form-item"
                >
                  <Select
                    mode="multiple"
                    value={newPreferences?.collections.summary?.columns}
                    maxTagCount="responsive"
                    onChange={(value) => {
                      setHasChanged(true);
                      setNewPreferences((prev) => {
                        if (prev)
                          return {
                            ...prev,
                            collections: {
                              ...prev.collections,
                              summary: {
                                ...prev.collections.summary,
                                columns: value,
                              },
                            },
                          };
                      });
                    }}
                    placeholder="Select Colums"
                    style={{ width: "100%" }}
                    optionLabelProp="label"
                  >
                    {columnsSummaryOptions.map((item) => (
                      <Option
                        key={item.value}
                        value={item.value}
                        disabled={item.disabled}
                        label={item.label}
                      >
                        <Checkbox
                          checked={newPreferences?.collections.summary?.columns.includes(
                            item.value
                          )}
                          disabled={item.disabled}
                        >
                          {item.label}
                        </Checkbox>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      ),
      icon: <FileDoneOutlined />,
    },
    {
      key: "2",
      label: "Detail",
      children: (
        <>
          <Form layout="horizontal">
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="Sort Default By:"
                  className="collections-form__form-item"
                >
                  <Select
                    showSearch
                    placeholder="Select a column to sort by"
                    optionFilterProp="children"
                    filterOption={filterOption}
                    value={
                      newPreferences?.collections?.detail?.defaultSortColumnKey
                        ?.name
                    }
                    onChange={(value) => {
                      setHasChanged(true);
                      setNewPreferences((prev) => {
                        if (prev)
                          return {
                            ...prev,
                            collections: {
                              ...prev.collections,
                              detail: {
                                ...prev.collections.detail,
                                defaultSortColumnKey: {
                                  ...prev.collections.detail
                                    .defaultSortColumnKey,
                                  name: value,
                                },
                              },
                            },
                          };
                      });
                    }}
                    options={detailOptions}
                  ></Select>
                </Form.Item>
                <Form.Item
                  label="Sorting order:"
                  className="collections-form__form-item"
                >
                  <Select
                    showSearch
                    placeholder="Sorting order"
                    optionFilterProp="children"
                    filterOption={filterOption}
                    value={
                      newPreferences &&
                      newPreferences.collections?.detail &&
                      newPreferences?.collections?.detail?.defaultSortColumnKey
                        ?.order
                    }
                    onChange={(value) => {
                      setHasChanged(true);
                      setNewPreferences((prev) => {
                        if (prev)
                          return {
                            ...prev,
                            collections: {
                              ...prev.collections,
                              detail: {
                                ...prev.collections.detail,
                                defaultSortColumnKey: {
                                  ...prev.collections.detail
                                    .defaultSortColumnKey,
                                  order: value,
                                },
                              },
                            },
                          };
                      });
                    }}
                    options={orderOptions}
                  />
                </Form.Item>
                <Form.Item
                  label="Columns"
                  className="collections-form__form-item"
                >
                  <Select
                    mode="multiple"
                    value={newPreferences?.collections?.detail?.columns}
                    maxTagCount="responsive"
                    onChange={(value) => {
                      setHasChanged(true);
                      setNewPreferences((prev) => {
                        if (prev)
                          return {
                            ...prev,
                            collections: {
                              ...prev.collections,
                              detail: {
                                ...prev.collections.detail,
                                columns: value,
                              },
                            },
                          };
                      });
                    }}
                    placeholder="Select Columns"
                    style={{ width: "100%" }}
                    optionLabelProp="label"
                  >
                    {columnsDetailsOptions.map((item) => (
                      <Option
                        key={item.value}
                        value={item.value}
                        disabled={item.disabled}
                        label={item.label}
                      >
                        <Checkbox
                          checked={newPreferences?.collections.detail?.columns?.includes(
                            item.value
                          )}
                          disabled={item.disabled}
                        >
                          {item.label}
                        </Checkbox>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      ),
      icon: <FileDoneOutlined />,
    },
  ];

  return (
    <>
      <Modal
        title={"Preferences"}
        open={open}
        footer={null}
        onCancel={onCancel}
        centered
      >
        <Tabs items={items} />
        <Row
          gutter={16}
          align="middle"
          justify="center"
          style={{ marginTop: "10px" }}
        >
          <Col>
            <Button type="default" loading={dataLoading} onClick={onCancel}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              disabled={!hasChanged}
              type="primary"
              loading={dataLoading}
              onClick={() => {
                if (isDemoEnv) {
                  openModalDemo();
                } else {
                  handleEditData({
                    endpoint: `user-preferences-collections/${profileInfo?._id}`,
                    newData: newPreferences,
                    next: () => {
                      setHasChanged(false);
                      navigate(0);
                    },
                  });
                }
              }}
            >
              Save Preferences
            </Button>
          </Col>
        </Row>
      </Modal>

      <DemoModal />
    </>
  );
};
