import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import { ProtectedRoute } from "./layout/routes/ProtectedRoutes";
import { PublicRoute } from "./layout/routes/PublicRoutes";
import { MidRoute } from "./layout/routes/MidRoutes";
import { config } from "./utils/theme/config";
import { AppPages } from "./layout/routes/Routes";
import AuthProvider from "./context/AuthProvider";
import QuickbooksProvider from "./context/QuickbooksProvider";
import DataProvider from "./context/DataProvider";
import "./globals.css";
import "./assets/sass/main.scss";
import { NotificationProvider } from "./context/NotificationProvider";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <NotificationProvider>
        <QuickbooksProvider>
          <DataProvider>
            <ConfigProvider {...config}>
              <Routes>
                {AppPages?.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      route.protected ? (
                        <>
                          {route.isProtectedMiddleware ? (
                            <MidRoute>{route.component}</MidRoute>
                          ) : (
                            <ProtectedRoute>{route.component}</ProtectedRoute>
                          )}
                        </>
                      ) : route.isMiddleware ? (
                        <MidRoute>{route.component}</MidRoute>
                      ) : (
                        <PublicRoute>{route.component}</PublicRoute>
                      )
                    }
                  />
                ))}
              </Routes>
            </ConfigProvider>
          </DataProvider>
        </QuickbooksProvider>
      </NotificationProvider>
    </AuthProvider>
  );
};

export default App;
