import React, { useContext } from "react";
import { ProtectedLayout } from "./ProtectedLayout";
import { Navigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

interface Props {
  children: JSX.Element | JSX.Element[];
  role?: null | "CLIENT" | "ADMIN" | "USER" | "DEMO";
}
export const ProtectedRoute = ({ role, children }: Props) => {
  const { validateToken, onLogout, role: userRole } = useContext(AuthContext);

  if (!validateToken()) {
    onLogout();
    return <Navigate to="/login" replace />;
  }

  if (role && role !== userRole) {
    return <Navigate to="/login" replace />;
  }

  return <ProtectedLayout>{children}</ProtectedLayout>;
};
