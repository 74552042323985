import { ColumnsType } from "antd/es/table";
import React from "react";
import { InvoiceProps } from "../../interfaces/interfaces";
import dayjs from "dayjs";
import { USDollar } from "../func/currency";
import { Button, Popconfirm, Space, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

export const transactionColumns = ({
  role,
  action,
}: {
  role: "CLIENT" | "ADMIN" | "USER" | "DEMO" | null;
  action: (record: InvoiceProps) => void;
}) => {
  const columns: ColumnsType<InvoiceProps> = [
    {
      title: "Num",
      dataIndex: "DocNumber",
      className: "table-row",
      sorter: {
        compare: (a, b) => a.DocNumber.localeCompare(b.DocNumber),
        multiple: 1,
      },
    },
    {
      title: "Customer name",
      dataIndex: "CustomerRef",
      className: "table-row",
      render: (_, { CustomerRef }) => (
        <>{CustomerRef ? CustomerRef.name : ""}</>
      ),
      sorter: {
        compare: (a, b) => a.CustomerRef.name.localeCompare(b.CustomerRef.name),
        multiple: 2,
      },
    },
    {
      title: "Created at",
      dataIndex: "MetaData",
      className: "table-row",
      render: (_, { MetaData }) => (
        <>{MetaData ? dayjs(MetaData.CreateTime).format("MM/DD/YYYY") : ""}</>
      ),
    },

    {
      title: "Due Date",
      dataIndex: "DueDate",
      className: "table-row",
      render: (params) => (
        <>{params ? dayjs(params).format("MM/DD/YYYY") : ""}</>
      ),
    },
    {
      title: "Open Balance",
      dataIndex: "Balance",
      className: "align-right table-row",
      render: (_, row) => <>{USDollar.format(row.Balance)}</>,
      sorter: {
        compare: (a, b) => a.Balance - b.Balance,
        multiple: 4,
      },
    },
    {
      title: "Action",
      key: "action",
      hidden: role === "CLIENT" || role === "DEMO",
      className: "center table-row",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() =>
              // record.DocNumber &&
              // idClient &&
              // handleBulkDeleteData("invoice", idClient, [record.DocNumber])
              action(record)
            }
          >
            <Tooltip title="Delete Invoice" placement="right">
              <Button type="link" icon={<DeleteOutlined />}></Button>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return columns;
};
