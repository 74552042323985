import {
  AlertComponentProps,
  ClientProps,
  CollectionProps,
  CustomerProps,
  DashboardProps,
  DetailProps,
  EmailPreviewProps,
  InvoiceProps,
  StatusProps,
  SummaryDataProps,
  SummaryProps,
  TemplateProps,
  UserProps,
} from "../interfaces/interfaces";

export const SUMMARY_INITIAL_STATE: SummaryProps[] = [
  {
    _id: { value: "", name: "" },
    idClient: "",
    current: [],
    "1-30": [],
    "31-60": [],
    "61-90": [],
    ">90": [],
    invoices: [],
    total: 0,
  },
];

export const SUMMARY_DATA_INITIAL_STATE: SummaryDataProps = {
  data: SUMMARY_INITIAL_STATE,
  total: {
    _id: { name: "TOTAL" },
    current: 0,
    "1-30": 0,
    "31-60": 0,
    "61-90": 0,
    ">90": 0,
    total: 0,
  },
};

export const DETAIL_INITIAL_STATE: DetailProps = {
  current: [],
  "1-30": [],
  "31-60": [],
  "61-90": [],
  ">90": [],
  totalCurrent: 0,
  total_1_30: 0,
  total_31_60: 0,
  total_61_90: 0,
  total_90: 0,
  total: 0,
};

export const DASHBOARD_INITIAL_STATE: DashboardProps = {
  invoicesByClient: [],
  total: 0,
  current: 0,
  total_1_30: 0,
  total_31_60: 0,
  total_61_90: 0,
  total_90: 0,
  top90: [],
};

export const COLLECTION_INITIAL_STATE: CollectionProps = {
  Status: {
    _id: "",
    Description: "",
    Label: "",
    Active: true,
  },
  LastContact: null,
  Notes: [""],
  Paid: false,
  PayDate: new Date(),
  LastUpdate: new Date(),
  PhoneNumber: "",
  EmailAddress: "",
  NewNote: "",
};

export const INVOICE_INITIAL_STATE: InvoiceProps = {
  Id: "",
  SyncToken: "",
  MetaData: {
    CreateTime: new Date(),
    LastUpdatedTime: new Date(),
  },
  DocNumber: "",
  TxnDate: new Date(),
  CustomerRef: {
    value: "",
    name: "",
  },
  SalesTermRef: {
    value: "",
    name: "",
  },
  DueDate: new Date(),
  TotalAmt: 0,
  BillEmail: {
    Address: "",
  },
  Balance: 0,
  CollectionDetails: COLLECTION_INITIAL_STATE,
  TransactionType: "Invoice",
};

export const DETAIL_INITIAL_STATE2: InvoiceProps[] = [INVOICE_INITIAL_STATE];

export const STATUS_INITIAL_LIST: StatusProps[] = [
  {
    _id: "",
    Label: "",
    Description: "",
    Active: true,
  },
];

export const ALERT_INITIAL_STATE = { status: false, message: "" };

export const ALERTCOMPONENT_INITIAL_STATE: AlertComponentProps = {
  message: "",
  description: "",
  type: "success",
  onClose: () => {},
};

export const TEMPLATE_INITIAL_STATE: TemplateProps = {
  _id: "",
  TemplateName: "",
  Subject: "",
  Default: false,
  TemplateContent: "",
  includePaymentLink: true,
  Active: true,
  Multiple: false,
  Initial: false,
  template_15: false,
  template_30: false,
  template_45: false,
  template_60: false,
  template_75: false,
  template_90: false,
};

export const CUSTOMER_INITIAL_STATE: CustomerProps = {
  _id: "",
  Taxable: false,
  BillAddr: {
    Id: "",
    Line1: "",
    City: "",
    CountrySubDivisionCode: "",
    PostalCode: "",
    Lat: "",
    Long: "",
  },
  ShipAddr: {
    Id: "",
    Line1: "",
    City: "",
    CountrySubDivisionCode: "",
    PostalCode: "",
    Lat: "",
    Long: "",
  },
  Job: false,
  BillWithParent: false,
  Balance: 0,
  BalanceWithJobs: 0,
  CurrencyRef: {
    value: "",
    name: "",
  },
  PreferredDeliveryMethod: "",
  domain: "",
  sparse: false,
  Id: "",
  SyncToken: "",
  MetaData: {
    CreateTime: "",
    LastUpdatedTime: "",
  },
  GivenName: "",
  FamilyName: "",
  FullyQualifiedName: "",
  CompanyName: "",
  DisplayName: "",
  PrintOnCheckName: "",
  Active: false,
  PrimaryPhone: {
    FreeFormNumber: "",
  },
  PrimaryEmailAddr: {
    Address: "",
  },
  Mobile: {
    FreeFormNumber: "",
  },
  Notes: "",
  CollectionsProfile: {
    Preferences: "",
    AdditionalNotes: "",
    PhoneNumber: "",
    EmailAddress: "",
  },
  ReminderSettings: {
    deliveryMethod: "",
    templateMultiple: null,
    templateInitial: null,
    template_15: null,
    template_30: null,
    template_45: null,
    template_60: null,
    template_75: null,
    template_90: null,
    frequency: null,
    allowAgingReminder: null,
    lastReminderDate: null,
    nextReminderDate: null,
    overdue: false,
  },
};

export const FILTERS_INITIAL_STATUS = {
  dates: null,
  customer: null,
  status: null,
};

export const PREFERENCES_INITIAL_STATE = {
  collections: {
    defaultHomepage: "",
    summary: {
      defaultSortColumnKey: { name: "", order: "" },
      columns: [],
    },
    detail: {
      defaultSortColumnKey: { name: "", order: "" },
    },
  },
};

export const CLIENT_INITIAL_STATE: ClientProps = {
  _id: "",
  name: "",
  phoneNumber: "",
  address: "",
  active: true,
  realmId: "",
  quickbooksStatus: { connected: true },
  email: "",
  contact: "",
  website: "",
};

export const MODAL_INITIAL_STATE = {
  open: false,
  create: true,
  update: false,
  multiple: false,
  payload: null,
};

export const INVOICES_WARNING =
  "Please note that deletion cannot be undone, and the status and notes will be permanently removed from our system, Ensure that you have reviewed the data you intend to delete and that you are certain of your decision.";

export const colors = [
  "#17549a",
  "#b2b2b2", // Original colors
  "#9a4b17",
  "#17409a",
  "#17329a",
  "#9a1754",
  "#549a17", // Complementary and analogous to #17549a
  "#123e73",
  "#0d2952", // Shades of #17549a
  "#1d221f",
  "#221d23",
  "#221d19",
  "#1e221d",
  "#1e1d22", // Complementary and analogous to #221d1e
  "#1a1718",
  "#130f10", // Shades of #221d1e
  "#b2b2b2",
  "#b2a7a7",
  "#a7b2b2", // Complementary and analogous to #b2b2b2
  "#8e8e8e",
  "#6a6a6a", // Shades of #b2b2b2
  "#5aa9e6",
  "#e6955a", // Accent colors
  "#f4f4f4",
  "#3a3a3a",
  "#f0c674", // Neutral and bright accents
  "#221d1e",
];

export const ERROR_ENCRYPTED = "Tm90IEF1dGhvcml6ZWQ=";

export const EMAIL_PREVIEW_INITIAL_STATE: EmailPreviewProps = {
  preview: false,
  to: [],
  cc: "",
  replyTo: "",
  subject: "",
  content: "",
  includePaymentLink: true,
  invoicePdf: "",
  signature: "",
};

export const USER_INITIAL_STATE: UserProps = {
  _id: "",
  name: "",
  username: "",
  username2: "",
  email: "",
  role: {
    _id: "",
    description: "User",
    permissions: "USER",
  },
  clientList: [],
  hasLoggedIn: false,
  active: true,
};
