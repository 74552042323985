import React from "react";
import {
  AdminClient,
  Login,
  SummaryClient,
  ReportPage,
  Settings,
  HelpPage,
  DashboardClient,
  DetailClient,
  AdminPage,
  DashboardPage,
  SummaryPage,
  DetailPage,
  TermsOfService,
  NotFundPage,
  PrivacyPolicy,
  ReportClient,
  InvoiceClient,
  PaymentClient,
  CreditMemoClient,
  DepositClient,
  StatusClient,
  JournalEntryClient,
  QuickbooksSettingsPage,
  QuickbooksSettingsClient,
  PresentationPage,
  AccountMiddleware,
  ChangePasswordMiddleware,
  CompanySettings,
  RevokeMiddleware,
  LaunchMiddleware,
  DisconnectMiddleware,
  CustomerPage,
  TemplatePage,
  UserListPage,
} from "../../pages";
import { Navigate } from "react-router-dom";

const routes = {
  initial: "/",
  about: "/about",
  account: "/accounts/:idUser/:realmId",
  revoke: "/revoke",
  disconnect: "/disconnect/:idUser",
  launch: "/launch",
  accountPassword: "/account-auth/:idClient",
  companySettings: "/company/:idClient",
  login: "/login",
  admin: "/admin",
  userList: "/users/:idClient",
  quickbooksPage: "/quickbooks",
  quickbooks: "/quickbooks/:idClient",
  adminClient: "/admin/:idClient",
  dashboard: "/dashboard",
  dashboardClient: "/dashboard/:idClient",
  summary: "/summary",
  summaryClient: "/summary/:idClient",
  detail: "/detail",
  detaiClient: "/detail/:idClient",
  customerPage: "/customer/:idClient",
  templatePage: "/template/:idClient",
  settings: "/settings/:idClient",
  status: "/status/:idClient",
  invoice: "/invoice/:idClient",
  credit: "/credit/:idClient",
  payment: "/payment/:idClient",
  deposit: "/deposit/:idClient",
  journal: "/journal/:idClient",
  help: "/help",
  report: "/report",
  reportClient: "/report/:idClient",
  terms: "/terms",
  privacy: "/privacy",
  notFound: "/*",
};

export const AppPages = [
  {
    path: routes.initial,
    protected: false,
    component: <Navigate to={routes.login} replace />,
  },
  { path: routes.login, protected: false, component: <Login /> },
  {
    path: routes.account,
    isMiddleware: true,
    component: <AccountMiddleware />,
  },
  {
    path: routes.launch,
    protected: true,
    isProtectedMiddleware: true,
    component: <LaunchMiddleware />,
  },
  {
    path: routes.launch,
    isMiddleware: true,
    component: <LaunchMiddleware />,
  },
  {
    path: routes.revoke,
    protected: true,
    isProtectedMiddleware: true,
    component: <RevokeMiddleware />,
    role: "ADMIN",
  },
  {
    path: routes.revoke,
    isMiddleware: true,
    component: <RevokeMiddleware />,
  },
  {
    path: routes.disconnect,
    protected: true,
    isProtectedMiddleware: true,
    component: <DisconnectMiddleware />,
    role: "ADMIN",
  },
  {
    path: routes.disconnect,
    isMiddleware: true,
    component: <DisconnectMiddleware />,
    role: "ADMIN",
  },

  {
    path: routes.accountPassword,
    isMiddleware: true,
    component: <ChangePasswordMiddleware />,
  },

  {
    path: routes.companySettings,
    protected: true,
    component: <CompanySettings />,
    role: "ADMIN",
  },
  {
    path: routes.userList,
    protected: true,
    component: <UserListPage />,
    role: "ADMIN",
  },
  { path: routes.about, protected: false, component: <PresentationPage /> },
  { path: routes.terms, isMiddleware: true, component: <TermsOfService /> },
  { path: routes.privacy, isMiddleware: true, component: <PrivacyPolicy /> },
  { path: routes.report, protected: true, component: <ReportPage /> },
  { path: routes.reportClient, protected: true, component: <ReportClient /> },
  {
    path: routes.admin,
    protected: true,
    component: <AdminPage />,
    role: "ADMIN",
  },
  { path: routes.status, protected: true, component: <StatusClient /> },
  { path: routes.invoice, protected: true, component: <InvoiceClient /> },
  { path: routes.payment, protected: true, component: <PaymentClient /> },
  { path: routes.credit, protected: true, component: <CreditMemoClient /> },
  { path: routes.deposit, protected: true, component: <DepositClient /> },
  { path: routes.journal, protected: true, component: <JournalEntryClient /> },
  { path: routes.adminClient, protected: true, component: <AdminClient /> },
  {
    path: routes.quickbooks,
    protected: true,
    component: <QuickbooksSettingsClient />,
    role: "ADMIN",
  },
  {
    path: routes.quickbooksPage,
    protected: true,
    component: <QuickbooksSettingsPage />,
    role: "ADMIN",
  },
  {
    path: routes.dashboardClient,
    protected: true,
    component: <DashboardClient />,
  },
  {
    path: routes.customerPage,
    protected: true,
    component: <CustomerPage />,
  },
  {
    path: routes.templatePage,
    protected: true,
    component: <TemplatePage />,
  },
  {
    path: routes.dashboard,
    protected: true,
    component: <DashboardPage />,
  },
  { path: routes.summaryClient, protected: true, component: <SummaryClient /> },
  { path: routes.summary, protected: true, component: <SummaryPage /> },
  {
    path: routes.detaiClient,
    protected: true,
    component: <DetailClient />,
  },
  {
    path: routes.detail,
    protected: true,
    component: <DetailPage />,
  },
  { path: routes.settings, protected: true, component: <Settings /> },
  { path: routes.help, protected: true, component: <HelpPage /> },
  { path: routes.report, protected: true, component: <ReportPage /> },
  { path: routes.notFound, protected: true, component: <NotFundPage /> },
  { path: routes.notFound, protected: false, component: <NotFundPage /> },
];
