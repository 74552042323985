import React, { useContext, useRef } from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "./AuthContext";
import { Base64 } from "js-base64";
import { isExpired, decodeToken } from "react-jwt";
import { useRequest } from "../hooks";
import { ALERT_INITIAL_STATE } from "../utils/data";
import {
  UserProps,
  UserAccountProps,
  ClientProps,
} from "../interfaces/interfaces";
interface Props {
  children: JSX.Element | JSX.Element[];
}

interface TokenProps {
  _id: string;
  name: string;
  user: string;
  role: {
    description: string;
    permissions: "CLIENT" | "ADMIN" | "USER" | "DEMO";
    _id: string;
  };
  realmId: string;
  client: ClientProps;
  clientList: UserAccountProps[];
}
const AuthProvider = ({ children }: Props) => {
  const { handleRequest } = useRequest();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSuccess, setIsSuccess] = useState(ALERT_INITIAL_STATE);
  const [isError, setIsError] = useState(ALERT_INITIAL_STATE);
  const [isLoading, setIsLoading] = useState(false);
  const [isWarning, setIsWarning] = useState<{
    status: boolean;
    message: string;
    actionLabel?: string;
    action?: () => void;
  }>({
    status: false,
    message: "",
  });
  const [clientInfo, setClientInfo] = useState<null | ClientProps>(null);
  const [clientList, setClientList] = useState<UserAccountProps[]>([]);
  const [clientIdSelected, setClientIdSelected] = useState("");
  const [isDemoEnv, setisDemoEnv] = useState(false);
  const [profileInfo, setProfileInfo] = useState<UserProps | null>(null);
  const [isModalResetPasswordVisible, setIsModalResetPasswordVisible] =
    useState(false);
  const [role, setRole] = useState<null | "CLIENT" | "ADMIN" | "USER" | "DEMO">(
    null
  );
  const isExecuting = useRef(false);

  useEffect(() => {
    const env = process.env.REACT_APP_ENV;
    if (env === "demo") {
      setisDemoEnv(true);
    }
  }, [process.env.REACT_APP_ENV]);

  const [userInfo, setUserInfo] = useState<null | {
    name: string;
    user: string;
    _id: string;
  }>(null);

  useEffect(() => {
    validateSession();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsSuccess(ALERT_INITIAL_STATE);
      setIsError(ALERT_INITIAL_STATE);
    }, 3000);
  }, [isError, isSuccess]);

  useEffect(() => {
    const tokenCoded = localStorage.getItem("info");
    if (tokenCoded) {
      const dataUser: TokenProps | null = decodeToken(tokenCoded);
      if (dataUser) {
        if (dataUser.clientList) {
          setClientList(dataUser.clientList as any);
        }
        setUserInfo({
          name: dataUser.name,
          user: dataUser.user,
          _id: dataUser._id,
        });
        setRole(dataUser.role.permissions);
      }
    }
  }, [localStorage.getItem("info")]);

  useEffect(() => {
    if (userInfo?._id) {
      getProfileInfo(userInfo._id);
    }
  }, [userInfo]);

  useEffect(() => {
    if (localStorage.getItem("selected")) {
      let alreadySelected = localStorage.getItem("selected");
      setClientIdSelected(alreadySelected as string);
    }
  }, []);

  useEffect(() => {
    if (clientList)
      setClientInfo(
        clientList[
          clientList.findIndex((client) => client._id === clientIdSelected)
        ] as any
      );
  }, [clientList, clientIdSelected]);

  useEffect(() => {
    if (localStorage.getItem("selected")) {
      let path = location.pathname.split("/")[1];
      let alreadySelected = localStorage.getItem("selected");
      setClientIdSelected(alreadySelected as string);
      if (path !== "revoke" && path !== "launch" && path !== "disconnect") {
        navigate(`/${path}/${alreadySelected}`);
      }
    }
  }, []);

  const handleWarning = (
    status: boolean,
    message: string,
    actionLabel?: string,
    action?: () => void
  ) => {
    setIsWarning({ status, message, actionLabel, action });
  };

  const handleSuccess = (status: boolean, message: string) => {
    setIsSuccess({ status, message });
  };

  const handleLoading = (status: boolean) => {
    setIsLoading(status);
  };

  const logoutHandler = async (next?: () => void) => {
    let options: RequestInit = {
      method: "GET",
    };
    setIsLoading(true);
    handleRequest({
      endpoint: `logout-collections`,
      options,
      onSuccess: () => {
        localStorage.clear();
        sessionStorage.clear();
        setIsLoggedIn(false);
        setIsLoading(false);
        next && next();
      },
      onError: (e) => {
        localStorage.clear();
        sessionStorage.clear();
        setIsLoggedIn(false);
        setIsLoading(false);
        next && next();
      },
    });
  };

  const removeAuthData = () => {
    setIsLoading(true);
    localStorage.clear();
    sessionStorage.clear();
    setIsLoggedIn(false);
    setIsLoading(false);
  };

  const loginHandler = (username: string, pass: string) => {
    setIsError(ALERT_INITIAL_STATE);
    setIsSuccess(ALERT_INITIAL_STATE);
    setIsLoading(true);
    try {
      const loop1Pass = Base64.encode(pass);
      const loop2Pass = Base64.encode(loop1Pass);
      const data = {
        user: username.toLowerCase(),
        password: loop2Pass,
      };
      let options: RequestInit = {
        method: "POST",
        body: JSON.stringify(data),
      };
      handleRequest({
        endpoint: "auth-collections",
        options,
        onSuccess: (response) => {
          if (response.data.token) {
            localStorage.setItem("info", response.data.token);
            const dataUser: TokenProps | null = decodeToken(
              response.data.token
            );
            if (dataUser) {
              if (dataUser.clientList && dataUser.clientList[0]) {
                setClientIdSelected(dataUser.clientList[0]?._id);
                localStorage.setItem("selected", dataUser.clientList[0]?._id);
                if (response.data.userInfo) {
                  const { userInfo } = response.data;
                  setProfileInfo(userInfo);
                  if (userInfo?.mustChangePassword) {
                    navigate(`/account-auth/${dataUser.clientList[0]._id}`);
                  } else {
                    navigate(`/dashboard/${dataUser.clientList[0]._id}`);
                  }
                } else {
                  navigate(`/dashboard/${dataUser.clientList[0]._id}`);
                }
                setIsLoading(false);
              }
            } else {
              logoutHandler(() => navigate("/login"));
            }
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setIsError({ status: true, message: "Not Authorized" });
          }
        },
        onError: (e) => {
          setIsLoading(false);
          setIsError({ status: true, message: e.message });
        },
      });
    } catch (e) {
      setIsLoading(false);
      setIsError({ status: true, message: "Not Authorized" });
    }
  };

  const handleLoginQuickbooks = (idClient?: string) => {
    setIsLoading(true);
    setIsError(ALERT_INITIAL_STATE);
    let client = clientIdSelected || idClient;
    if (client) {
      setIsLoading(true);
      let options: RequestInit = {
        method: "GET",
      };
      handleRequest({
        endpoint: `auth-uri-collections/${clientIdSelected}`,
        options,
        onSuccess: (response) => {
          if (response.data) {
            if (response.data.authUri) {
              window.location.replace(response.data.authUri);
            } else {
              setIsError({ status: true, message: "An error has occurred" });
            }
          } else {
            setIsLoading(false);
            setIsError({ status: true, message: "An error has occurred" });
            logoutHandler(() => navigate("/login"));
          }
        },
        onError: (e) => {
          setIsLoading(false);
          setIsError({ status: true, message: "An error has occurred" });
          e.status === 401 && logoutHandler(() => navigate("/login"));
          if (e.message === "Not Authorized") logoutHandler(() => navigate(0));
        },
      });
    } else {
      setIsError({ status: true, message: "Please try again later" });
      logoutHandler(() => navigate(0));
    }
  };
  const getProfileInfo = (idUser: string) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest({
      endpoint: `profile-info/${idUser}`,
      options,
      onSuccess: (response) => {
        if (response.data) {
          if (response.data) {
            setProfileInfo(response.data);
          }
        }
        setIsLoading(false);
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: "An error has occurred" });
        e.status === 401 && logoutHandler(() => navigate(0));
        if (e.message === "Not Authorized") logoutHandler(() => navigate(0));
      },
    });
  };
  const handleLoginQuickbooksSSO = () => {
    setIsError(ALERT_INITIAL_STATE);
    setIsSuccess(ALERT_INITIAL_STATE);
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest({
      endpoint: `auth-collections-validate-userinfo-sso`,
      options,
      onSuccess: (response) => {
        if (response.data) {
          if (response.data.authUri) {
            window.location.replace(response.data.authUri);
          } else {
            setIsError({ status: true, message: "An error has occurred" });
          }
        } else {
          setIsLoading(false);
          setIsError({ status: true, message: "An error has occurred" });
          logoutHandler(() => navigate("/login"));
        }
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: "An error has occurred" });
      },
    });
  };

  const handleLoginDemo = (email: string) => {
    setIsLoading(true);
    setIsError(ALERT_INITIAL_STATE);
    setIsLoading(true);
    let options: RequestInit = {
      method: "POST",
      body: JSON.stringify({ email }),
    };
    handleRequest({
      endpoint: `login-demo-collections`,
      options,
      onSuccess: (response) => {
        if (response.data.token) {
          localStorage.setItem("info", response.data.token);
          const dataUser: TokenProps | null = decodeToken(response.data.token);
          if (dataUser) {
            if (dataUser.clientList && dataUser.clientList[0]) {
              setClientIdSelected(dataUser.clientList[0]?._id);
              localStorage.setItem("selected", dataUser.clientList[0]?._id);
              navigate(`/dashboard/${dataUser.clientList[0]._id}`);
              setIsLoading(false);
            }
          } else {
            logoutHandler(() => navigate("/login"));
          }
          if (response.data.userInfo) {
            setProfileInfo(response.data.userInfo);
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setIsError({ status: true, message: "Not Authorized" });
        }
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: "Not Authorized" });
      },
    });
  };

  const validateSession = () => {
    if (validateToken()) {
      setIsLoggedIn(true);
      setIsLoading(false);
    } else {
      logoutHandler(() => navigate("/login"));
      // handleRefreshTokenCollections(() => navigate("/dashboard"));
    }
  };

  const validateToken = (): boolean => {
    const token = localStorage.getItem("info");
    if (token) {
      const expired = isExpired(token);
      if (expired) return false;
    } else {
      return false;
    }
    return true;
  };

  const handleRefreshTokenCollections = (next?: () => void) => {
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest({
      endpoint: `refresh-token-collections`,
      options,
      onSuccess: (response) => {
        if (response.data.token) {
          localStorage.setItem("info", response.data.token);
          const tokenCoded = response.data.token;
          if (tokenCoded) {
            const dataUser: TokenProps | null = decodeToken(tokenCoded);
            if (dataUser) {
              if (dataUser.clientList) {
                setClientList(dataUser.clientList as any);
              }
              setUserInfo({
                name: dataUser.name,
                user: dataUser.user,
                _id: dataUser._id,
              });
              next && next();
            }
          }
        }
      },
      onError: (e) => {
        if (e.message === "Not Authorized")
          logoutHandler(() => navigate("/login"));
      },
    });
  };

  const getClientId = () => {
    if (clientIdSelected) {
      return clientIdSelected;
    } else if (localStorage.getItem("selected")) {
      let clientId = localStorage.getItem("selected");
      clientId && setClientIdSelected(clientId);
      return clientId;
    } else {
      const tokenCoded = localStorage.getItem("info");
      if (tokenCoded) {
        const dataUser: TokenProps | null = decodeToken(tokenCoded);
        if (dataUser) return dataUser.clientList[0]._id;
      }
      return "";
    }
  };

  const handleChangeClientSelected = (id: string, customRedirect?: boolean) => {
    localStorage.setItem("selected", id);
    setClientIdSelected(id);
    !customRedirect && navigate(`/dashboard/${id}`);
  };

  const getQbData = (idClient: string, next: () => void) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest({
      endpoint: `quickbooks-collections-data/${idClient}`,
      options,
      onSuccess: (response) => {
        if (response.success) {
          sessionStorage.setItem(
            "totals",
            JSON.stringify(response.data.totals)
          );
        }
      },
      onError: (e) => {
        // if (e.message === "Not Authorized")
        //   logoutHandler(() => navigate("/login"));
        setIsLoading(false);
      },
    });
    next();
  };

  const validateExternalSession = ({
    token,
    realmId,
    clientId,
    launch,
    revoke,
  }: {
    token: string;
    realmId?: string;
    clientId?: string;
    launch?: boolean;
    revoke?: boolean;
  }) => {
    if (isExecuting.current) return;
    isExecuting.current = true;
    validateExternalSessionProcess({
      token,
      realmId,
      clientId,
      launch,
      revoke,
    });
  };

  const validateExternalSessionProcess = ({
    token,
    realmId,
    clientId,
    launch,
    revoke,
  }: {
    token: string;
    realmId?: string;
    clientId?: string;
    launch?: boolean;
    revoke?: boolean;
  }) => {
    try {
      if (localStorage.getItem("info")) {
        localStorage.removeItem("info");
      }
      handleRequest({
        endpoint: `external-collections/?authToken=${token}`,
        onSuccess: (response) => {
          if (response.data.userInfo) {
            setProfileInfo(response.data.userInfo);
          }
          if (response.data.token) {
            localStorage.setItem("info", response.data.token);
            const dataUser: TokenProps | null = decodeToken(
              response.data.token
            );
            if (dataUser) {
              if (revoke) {
                navigate(`/disconnect/${dataUser._id}`);
                return;
              }
              if (realmId) {
                if (launch) {
                  navigate(
                    `/accounts/${dataUser._id}/${realmId}/?launch=${true}`
                  );
                } else {
                  navigate(`/accounts/${dataUser._id}/${realmId}`);
                }
              } else {
                if (launch) {
                  handleChangeClientSelected(dataUser.clientList[0]._id);
                  navigate(`/dashboard/${dataUser.clientList[0]._id}`);
                } else {
                  getQbData(dataUser.clientList[0]._id, () => {
                    handleChangeClientSelected(dataUser.clientList[0]._id);
                    navigate(`/dashboard/${dataUser.clientList[0]._id}`);
                  });
                }
              }
            } else if (clientId) {
              if (launch) {
                handleChangeClientSelected(clientId);
                navigate(`/dashboard/${clientId}`);
              } else {
                getQbData(clientId, () => {
                  handleChangeClientSelected(clientId);
                  navigate(`/dashboard/${clientId}`);
                });
              }
            }
          } else {
            setIsLoading(false);
            setIsError({ status: true, message: "Not Authorized" });
            logoutHandler(() => navigate("/login"));
          }
        },
        onError: (e) => {
          setIsLoading(false);
          setIsError({ status: true, message: "Not Authorized" });
          logoutHandler(() => navigate("/login"));
        },
      });
      isExecuting.current = false;
    } catch (e) {
      setIsLoading(false);
      setIsError({ status: true, message: "Not Authorized" });
      logoutHandler(() => navigate("/login"));
    }
  };

  const handleSendResetPassword = ({
    username,
    next,
  }: {
    username: string;
    next: () => void;
  }) => {
    try {
      setIsLoading(true);
      let options: RequestInit = {
        method: "POST",
        headers: {
          "x-origin": "login.quattroapps.app",
        },
        body: JSON.stringify({ username }),
      };
      handleRequest({
        endpoint: `forgot-password`,
        options,
        onSuccess: (response) => {
          setIsLoading(false);
          if (response) {
            setIsSuccess({ status: true, message: "Password Sent" });
            next();
          }
        },
        onError: (e) => {
          setIsLoading(false);
          setIsError({ status: true, message: e?.message });
        },
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setIsError({ status: true, message: "Not Authorized" });
    }
  };

  const compareToken = (token: string) => {
    const tokenCoded = localStorage.getItem("info");
    const sourceToken: TokenProps | null = decodeToken(token);
    if (tokenCoded) {
      const dataUser: TokenProps | null = decodeToken(tokenCoded);
      if (dataUser && sourceToken) {
        if (dataUser._id === sourceToken._id) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        role,
        authSuccess: isSuccess,
        authError: isError,
        authLoading: isLoading,
        clientInfo,
        userInfo,
        dataWarning: isWarning,
        isDemoEnv,
        clientList,
        clientIdSelected,
        profileInfo,
        isModalResetPasswordVisible,
        openCloseModalResetPassword: (value) =>
          setIsModalResetPasswordVisible(value),
        handleSendResetPassword,
        changeProfileInfo: (value: UserProps) => setProfileInfo(value),
        handleChangeClientSelected,
        handleWarning,
        handleSuccess,
        handleLoading,
        onLogin: loginHandler,
        onLogout: logoutHandler,
        removeAuthData,
        validateToken,
        getClientId,
        handleLoginQuickbooks,
        validateExternalSession,
        handleLoginQuickbooksSSO,
        handleLoginDemo,
        handleRefreshTokenCollections,
        compareToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context)
    throw new Error("Something went wrong with your Authentication");
  return context;
};
