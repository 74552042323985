import {
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  List,
  Popconfirm,
  Row,
  Tabs,
  TabsProps,
  Tag,
  Tooltip,
  Tour,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  CardComponent,
  CardTitle,
  ColumnComponent,
} from "../../../components/ui";
import Table from "antd/es/table";
import DataContext from "../../../context/DataContext";
import { useParams } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import {
  DownloadOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  FolderAddOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { SearchInput } from "../../../components/functional";
import { TemplateProps } from "../../../interfaces/interfaces";
import type { TablePaginationConfig } from "antd/es/table";
import type { ColumnsType, FilterValue } from "antd/es/table/interface";
import { TableProps, TourProps } from "antd/lib";
import { useDemoModal } from "../../../hooks";
import { exportTemplateFile } from "../../../utils/func/ExportFile";
import { ModalTemplateCreateOrUpdate } from "../../../components/customer";
import {
  MODAL_INITIAL_STATE,
  TEMPLATE_INITIAL_STATE,
} from "../../../utils/data";

type OnChange = NonNullable<TableProps<TemplateProps>["onChange"]>;
type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

const variablesMultiple = ["CustomerName", "BusinessName"];
const variablesSingle = [
  "CustomerName",
  "BusinessName",
  "InvoiceId",
  "InvoiceDate",
  "DueDate",
  "Balance",
  "CustomField1",
  "CustomField2",
  "CustomField3",
];

export const TemplatePage = () => {
  const { idClient } = useParams();
  const { clientInfo, isDemoEnv, profileInfo } = useContext(AuthContext);
  const { DemoModal, openModalDemo } = useDemoModal();
  const [sortedInfo, setSortedInfo] = useState<Sorts>({});
  const {
    isTourOpen,
    customerByClient,
    templateList,
    getData,
    handleTour,
    handleEditData,
    handleBulkEdit,
    handleCreateData,
  } = useContext(DataContext);
  const [selectedRowsToEdit, setSelectedRowToEdit] = useState<React.Key[]>([]);
  const [modalStatus, setModalStatus] = useState<{
    open: boolean;
    create: boolean;
    update: boolean;
    multiple: boolean;
    payload?: TemplateProps | null;
  }>(MODAL_INITIAL_STATE);
  const [dataTableSize] = useState<number>(100);
  const [tableParams, setTableParams] = useState<TableParams>({});
  const [searchQuery, setSearchQuery] = useState("");
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);

  useEffect(() => {
    if (idClient) {
      getData("customer", idClient);
      getData("template", idClient);
    }
  }, [idClient]);

  useEffect(() => {
    setSearchQuery("");
    if (isDemoEnv) {
      let tourOpened = localStorage.getItem("tour-summary");
      if (!tourOpened) {
        handleTour(true);
        localStorage.setItem("tour-customer", "true");
      }
    }
  }, []);

  useEffect(() => {
    const defaultSortKey = profileInfo?.preferences?.collections?.templates
      ?.defaultSortColumnKey || { name: "_id", order: "ascend" };
    setSortedInfo({
      columnKey: defaultSortKey.name,
      order: defaultSortKey.order as "ascend" | "descend",
    });
  }, [profileInfo]);

  useEffect(() => {
    setTableParams({
      pagination: {
        pageSize: dataTableSize,
        total: customerByClient.length,
        onChange: (_, pageSize) => {
          setTableParams((prev) => {
            return {
              ...prev,
              pagination: {
                ...prev.pagination,
                pageSize: pageSize,
              },
            };
          });
        },
      },
    });
  }, [customerByClient]);

  const onChangeSelectedRowsToEdit = (newSelectedRowToEdit: React.Key[]) => {
    setSelectedRowToEdit(newSelectedRowToEdit);
  };

  const rowSelectionToEdit = {
    selectedRowKeys: selectedRowsToEdit,
    onChange: onChangeSelectedRowsToEdit,
  };

  const handleChange: OnChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter as Sorts);
  };

  const steps: TourProps["steps"] = [
    {
      title: "Toolbar",
      description:
        "This is the toolbar, where you can find all the actions, filters, and export options.",
      target: () => ref1.current,
    },

    {
      title: "Edit Single/Multiple Template(s)",
      description:
        "Edit Single/Multiple Template(s) by selecting the checkbox and clicking the Edit button.",
      target: () => ref3.current,
    },
    {
      title: "Create New Template",
      description: "Create a new Template for the Customer Reminder.",
      target: () => ref2.current,
    },
    {
      title: "Export Data",
      description: "Export the Template list data to a CSV file.",
      target: () => ref5.current,
    },
  ];

  const hasSelected = selectedRowsToEdit.length > 0;

  const columns: ColumnsType<TemplateProps> = [
    {
      title: "Template Name",
      dataIndex: "TemplateName",
      key: "TemplateName",
      className: "table-row",
      width: 5,
      onCell: (record, index) => {
        return {
          onClick: () =>
            setModalStatus({
              open: true,
              create: false,
              update: true,
              multiple: false,
              payload: record,
            }),
        };
      },
      sorter: {
        compare: (a, b) => a.TemplateName.localeCompare(b.TemplateName),
        multiple: 1,
      },
      sortOrder:
        sortedInfo.columnKey === "TemplateName" ? sortedInfo.order : null,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: "Subject",
      dataIndex: "Subject",
      key: "Subject",
      className: "table-row",
      onCell: (record, index) => {
        return {
          onClick: () =>
            setModalStatus({
              open: true,
              create: false,
              update: true,
              multiple: false,
              payload: record,
            }),
        };
      },
      render: (_, template) => (
        <div
          style={{
            width: "20vw",
            marginTop: "10px",
            padding: "10px",
            whiteSpace: "pre-wrap", // To ensure formatting like line breaks is maintained
          }}
        >
          {renderTextContent(template.Subject, template.Multiple)}
        </div>
      ),
      sorter: {
        compare: (a, b) => a.Subject.localeCompare(b.Subject),
        multiple: 1,
      },
      sortOrder: sortedInfo.columnKey === "Subject" ? sortedInfo.order : null,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: "Content",
      dataIndex: "TemplateContent",
      key: "TemplateContent",
      responsive: ["md", "lg", "xl", "xxl"],
      className: "table-row",
      render: (_, template) => (
        <div
          style={{
            width: "20vw",
            marginTop: "10px",
            padding: "10px",
            whiteSpace: "pre-wrap", // To ensure formatting like line breaks is maintained
          }}
        >
          {renderTextContent(template.TemplateContent, template.Multiple)}
          {template.signature && <div>{template.signature}</div>}
        </div>
      ),
      onCell: (record, index) => {
        return {
          onClick: () =>
            setModalStatus({
              open: true,
              create: false,
              update: true,
              multiple: false,
              payload: record,
            }),
        };
      },
      sortOrder:
        sortedInfo.columnKey === "TemplateContent" ? sortedInfo.order : null,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Default",
      dataIndex: "Default",
      key: "Default",
      className: "table-row align-center",
      render: (_, { Default, _id, Active }) => (
        <Checkbox
          checked={Default}
          disabled={Default}
          onChange={(event) => {
            if (isDemoEnv) {
              openModalDemo();
            } else {
              idClient &&
                handleEditData({
                  endpoint: `template/${idClient}/${_id}`,
                  newData: { Default: event.target.checked },
                  next: () => getData("template", idClient),
                });
            }
          }}
        />
      ),
      sortOrder: sortedInfo.columnKey === "Default" ? sortedInfo.order : null,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      className: "table-row align-center",
      render: (_, template) => (
        <Button
          type="link"
          icon={<EditOutlined />}
          onClick={() =>
            setModalStatus({
              open: true,
              create: false,
              update: true,
              multiple: true,
              payload: template,
            })
          }
        ></Button>
      ),
      onCell: (record, index) => {
        return {
          onClick: () =>
            setModalStatus({
              open: true,
              create: false,
              update: true,
              multiple: false,
              payload: record,
            }),
        };
      },
    },

    {
      title: "Delete",
      dataIndex: "Delete",
      key: "Delete",
      className: "table-row align-center",
      render: (_, { Active, _id, Default }) => (
        <>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => {
              if (isDemoEnv) {
                openModalDemo();
              } else {
                idClient &&
                  handleEditData({
                    endpoint: `template/${idClient}/${_id}`,
                    newData: { Active: false },
                    next: () => getData("template", idClient),
                  });
              }
            }}
          >
            <Tooltip title="Remove Template" placement="bottom">
              <Button
                icon={<DeleteOutlined />}
                disabled={Default}
                type="link"
              />
            </Tooltip>
          </Popconfirm>
        </>
      ),
      sortOrder: sortedInfo.columnKey === "Active" ? sortedInfo.order : null,
      ellipsis: {
        showTitle: false,
      },
    },
  ];

  const createOrUpdateTemplate = (
    values: TemplateProps,
    idClient: string,
    idTemplate?: string
  ) => {
    if (modalStatus.create) {
      handleCreateData({
        endpoint: "template",
        idClient,
        data: values,
        next: () => getData("template", idClient),
      });
    } else if (modalStatus.update && idTemplate) {
      handleEditData({
        endpoint: `template/${idClient}/${idTemplate}`,
        newData: values,
        next: () => getData("template", idClient),
      });
    } else if (modalStatus.multiple) {
      handleBulkEdit({
        endpoint: `template-bulk/${idClient}`,
        items: selectedRowsToEdit as string[],
        newData: {
          newValues: values,
        },
        next: () => getData("template", idClient),
      });
    }
  };

  const variableRegex = /{{(.*?)}}/g;

  const renderTextContent = (content: string, multiple: boolean) => {
    const parts = content.split(variableRegex);
    return parts.map((part: string, index: number) => {
      const formattedPart = part.split("\n").map((line, i) => (
        <React.Fragment key={i}>
          {line}
          {i < part.split("\n").length - 1 && <br />}
        </React.Fragment>
      ));
      if (index % 2 === 1) {
        // This is a variable, make it uneditable
        let variableValues = multiple ? variablesMultiple : variablesSingle;
        if (variableValues.includes(part)) {
          return (
            <Tag key={index} color="blue" style={{ marginRight: 4 }}>
              {`{{${part}}}`}
            </Tag>
          );
        } else {
          return <span key={index}>{formattedPart}</span>;
        }
      }
      // Regular text
      return <span key={index}>{formattedPart}</span>;
    });
  };

  const renderItem = (item: TemplateProps) => (
    <List.Item>
      <Card
        title={item.TemplateName}
        extra={[
          <Tooltip title="Edit Template">
            <Button
              icon={<EditOutlined />}
              type="link"
              onClick={() =>
                setModalStatus({
                  open: true,
                  create: false,
                  update: true,
                  multiple: false,
                  payload: item,
                })
              }
            ></Button>
          </Tooltip>,
          <Divider type="vertical" style={{ height: "100%" }}></Divider>,
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => {
              if (isDemoEnv) {
                openModalDemo();
              } else {
                idClient &&
                  handleEditData({
                    endpoint: `template/${idClient}/${item._id}`,
                    newData: { Active: false },
                    next: () => getData("template", idClient),
                  });
              }
            }}
          >
            <Tooltip title="Remove Template" placement="bottom">
              <Button icon={<DeleteOutlined />} type="link" />
            </Tooltip>
          </Popconfirm>,
        ]}
      >
        <>Subject: {renderTextContent(item.Subject, item.Multiple)}</>
        <div style={{ marginTop: "10px", textAlign: "justify" }}>
          {renderTextContent(item.TemplateContent, item.Multiple)}
          {item.signature && (
            <>
              <br />
              <br />
              <code style={{ whiteSpace: "pre-wrap" }}>{item.signature}</code>
            </>
          )}
        </div>
      </Card>
    </List.Item>
  );

  const renderExtraActions = (templateType: string) => {
    return (
      <Tooltip title="Create New Template">
        <Button
          icon={<FolderAddOutlined />}
          type="link"
          onClick={() => {
            setModalStatus({
              open: true,
              create: true,
              update: false,
              multiple: false,
              payload: {
                ...TEMPLATE_INITIAL_STATE,
                [templateType]: true,
                Default: false,
              },
            });
          }}
        ></Button>
      </Tooltip>
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Listing Templates",
      children: (
        <>
          {templateList.filter((template) => template.Multiple).length === 0 ? (
            <Card
              title="No Templates Found"
              className="summary-client__card"
              extra={renderExtraActions("Multiple")}
              actions={[
                <Button
                  type="link"
                  onClick={() => {
                    setModalStatus({
                      open: true,
                      create: true,
                      update: false,
                      multiple: false,
                      payload: {
                        ...TEMPLATE_INITIAL_STATE,
                        Multiple: true,
                        Default: false,
                        Initial: false,
                      },
                    });
                  }}
                >
                  Create New Template
                </Button>,
              ]}
            >
              <p>No Templates found</p>
            </Card>
          ) : (
            <Table
              bordered
              columns={columns}
              onChange={handleChange}
              rowSelection={rowSelectionToEdit}
              dataSource={templateList.filter((template) => template.Multiple)}
              className="summary-client__table"
              size="small"
              rowKey={"_id"}
              tableLayout="auto"
            />
          )}
        </>
      ),
    },
    {
      key: "2",
      label: "Individual Templates",
      children: (
        <>
          {templateList.filter(
            (template) =>
              template.Initial ||
              template.template_15 ||
              template.template_30 ||
              template.template_45 ||
              template.template_60 ||
              template.template_75 ||
              template.template_90
          ).length === 0 ? (
            <Card
              title="No Individual Templates Found"
              className="summary-client__card"
              extra={renderExtraActions("Initial")}
              actions={[
                <Button
                  type="link"
                  onClick={() => {
                    setModalStatus({
                      open: true,
                      create: true,
                      update: false,
                      multiple: false,
                      payload: {
                        ...TEMPLATE_INITIAL_STATE,
                        Initial: true,
                        Default: false,
                      },
                    });
                  }}
                >
                  Create New Template
                </Button>,
              ]}
            >
              <p>No Templates found</p>
            </Card>
          ) : (
            <div
              style={{
                maxHeight: "40vh",
                scrollbarColor: "auto",
                overflowY: "scroll",
              }}
            >
              {templateList.filter((template) => template.Initial).length >
                0 && (
                <Card
                  title="Default Template"
                  bordered
                  className="summary-client__card"
                  style={{ marginBottom: "20px", marginRight: "20px" }}
                  extra={renderExtraActions("Initial")}
                >
                  <List
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 1,
                      md: 1,
                      lg: 2,
                      xl: 2,
                      xxl: 2,
                    }}
                    dataSource={templateList.filter(
                      (template) => template.Initial
                    )}
                    renderItem={(item) => renderItem(item)}
                  />
                </Card>
              )}

              {templateList.filter((template) => template.template_15).length >
                0 && (
                <Card
                  title="15+ Days"
                  className="summary-client__card"
                  style={{ marginBottom: "20px", marginRight: "20px" }}
                  extra={renderExtraActions("template_15")}
                >
                  <List
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 1,
                      md: 1,
                      lg: 2,
                      xl: 2,
                      xxl: 2,
                    }}
                    dataSource={templateList.filter(
                      (template) => template.template_15
                    )}
                    renderItem={(item) => renderItem(item)}
                  />
                </Card>
              )}
              {templateList.filter((template) => template.template_30).length >
                0 && (
                <Card
                  title="30+ Days"
                  className="summary-client__card"
                  style={{ marginBottom: "20px", marginRight: "20px" }}
                  extra={renderExtraActions("template_30")}
                >
                  <List
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 1,
                      md: 1,
                      lg: 2,
                      xl: 2,
                      xxl: 2,
                    }}
                    dataSource={templateList.filter(
                      (template) => template.template_30
                    )}
                    renderItem={(item) => renderItem(item)}
                  />
                </Card>
              )}
              {templateList.filter((template) => template.template_45).length >
                0 && (
                <Card
                  title="45+ Days"
                  className="summary-client__card"
                  style={{ marginBottom: "20px", marginRight: "20px" }}
                  extra={renderExtraActions("template_45")}
                >
                  <List
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 1,
                      md: 1,
                      lg: 2,
                      xl: 2,
                      xxl: 2,
                    }}
                    dataSource={templateList.filter(
                      (template) => template.template_45
                    )}
                    renderItem={(item) => renderItem(item)}
                  />
                </Card>
              )}
              {templateList.filter((template) => template.template_60).length >
                0 && (
                <Card
                  title="60+ Days"
                  className="summary-client__card"
                  style={{ marginBottom: "20px", marginRight: "20px" }}
                  extra={renderExtraActions("template_60")}
                >
                  <List
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 1,
                      md: 1,
                      lg: 2,
                      xl: 2,
                      xxl: 2,
                    }}
                    dataSource={templateList.filter(
                      (template) => template.template_60
                    )}
                    renderItem={(item) => renderItem(item)}
                  />
                </Card>
              )}
              {templateList.filter((template) => template.template_75).length >
                0 && (
                <Card
                  title="75+ Days"
                  className="summary-client__card"
                  style={{ marginBottom: "20px", marginRight: "20px" }}
                  extra={renderExtraActions("template_75")}
                >
                  <List
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 1,
                      md: 1,
                      lg: 2,
                      xl: 2,
                      xxl: 2,
                    }}
                    dataSource={templateList.filter(
                      (template) => template.template_75
                    )}
                    renderItem={(item) => renderItem(item)}
                  />
                </Card>
              )}
              {templateList.filter((template) => template.template_90).length >
                0 && (
                <Card
                  title="90+ Days"
                  className="summary-client__card"
                  style={{ marginBottom: "20px", marginRight: "20px" }}
                  extra={renderExtraActions("template_90")}
                >
                  <List
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 1,
                      md: 1,
                      lg: 2,
                      xl: 2,
                      xxl: 2,
                    }}
                    dataSource={templateList.filter(
                      (template) => template.template_90
                    )}
                    renderItem={(item) => renderItem(item)}
                  />
                </Card>
              )}
            </div>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <CardTitle title={clientInfo?.name + " - Templates"} />
        </ColumnComponent>
        <ColumnComponent>
          <div ref={ref1}>
            <CardComponent
              className="summary-client__card"
              title={"Templates"}
              extra={
                <SearchInput
                  placeholder="Search by name"
                  onSearch={(value: string) => {
                    if (value) {
                      setSearchQuery(value);
                      idClient && getData("template", idClient, value);
                    } else {
                      setSearchQuery("");
                      idClient && getData("template", idClient);
                    }
                  }}
                />
              }
              actions={[
                <div ref={ref3}>
                  <Tooltip title="Edit Selected Template(s)" placement="left">
                    <Button
                      type="link"
                      icon={<EditOutlined />}
                      disabled={!hasSelected}
                      size="large"
                      onClick={() =>
                        setModalStatus({
                          open: true,
                          create: false,
                          update: true,
                          multiple: true,
                          payload:
                            selectedRowsToEdit.length === 1
                              ? templateList.find(
                                  (template) =>
                                    template._id === selectedRowsToEdit[0]
                                )
                              : null,
                        })
                      }
                    />
                  </Tooltip>
                </div>,
                <div ref={ref2}>
                  <Tooltip title="Create new Template" placement="left">
                    <Button
                      type="link"
                      icon={<FolderAddOutlined />}
                      size="large"
                      onClick={() =>
                        setModalStatus({
                          open: true,
                          create: true,
                          update: false,
                          multiple: false,
                          payload: {
                            ...TEMPLATE_INITIAL_STATE,
                            Initial: true,
                            Default: false,
                          },
                        })
                      }
                    />
                  </Tooltip>
                </div>,
                <div ref={ref5}>
                  <Tooltip
                    title={
                      selectedRowsToEdit.length > 0
                        ? "Export Selected Data"
                        : "Export Data"
                    }
                    placement="left"
                  >
                    <Button
                      type="link"
                      icon={<DownloadOutlined />}
                      size="large"
                      onClick={() => {
                        exportTemplateFile(
                          templateList,
                          selectedRowsToEdit as string[]
                        );
                      }}
                    />
                  </Tooltip>
                </div>,
              ]}
            ></CardComponent>
          </div>
        </ColumnComponent>
        <ColumnComponent>
          <Tabs defaultActiveKey="1" items={items}></Tabs>
        </ColumnComponent>
        <ColumnComponent>
          <div style={{ textAlign: "right" }}>
            <Tooltip title="Help">
              <Button
                icon={<QuestionCircleOutlined style={{ color: "#17549a" }} />}
                type="default"
                onClick={() => {
                  handleTour(true);
                }}
              />
            </Tooltip>
          </div>
        </ColumnComponent>
      </Row>
      <ModalTemplateCreateOrUpdate
        title="Create/Update Template"
        modalStatus={modalStatus}
        onSubmit={(values) => {
          if (isDemoEnv) {
            openModalDemo();
          } else {
            if (clientInfo && idClient) {
              createOrUpdateTemplate(values, idClient, values._id);
              setModalStatus(MODAL_INITIAL_STATE);
            }
          }
        }}
        onCancel={() => {
          setModalStatus(MODAL_INITIAL_STATE);
        }}
      />
      <Tour open={isTourOpen} onClose={() => handleTour(false)} steps={steps} />
      <DemoModal />
    </>
  );
};
