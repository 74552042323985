import {
  Button,
  Card,
  DatePicker,
  Popconfirm,
  Row,
  Select,
  Tabs,
  TabsProps,
  Tooltip,
  Tour,
  Typography,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CardComponent, CardTitle, ColumnComponent } from "../../components/ui";
import Table from "antd/es/table";
import DataContext from "../../context/DataContext";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import {
  DownloadOutlined,
  MailOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  FolderAddOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import { MultipleSelect, SearchInput } from "../../components/functional";
import { USDollar } from "../../utils/func/currency";
import { CustomerProps, TemplateProps } from "../../interfaces/interfaces";
import type { TablePaginationConfig } from "antd/es/table";
import type { ColumnsType, FilterValue } from "antd/es/table/interface";
import { TableProps, TourProps } from "antd/lib";
import { useDemoModal } from "../../hooks";
import { exportCustomerFile } from "../../utils/func/ExportFile";
import {
  CustomerDrawer,
  ModalCustomerBulkUpdate,
  ModalTemplateCreateOrUpdate,
} from "../../components/customer";
import QuickbooksContext from "../../context/QuickbooksContext";
import dayjs from "dayjs";
import { MODAL_INITIAL_STATE, TEMPLATE_INITIAL_STATE } from "../../utils/data";
import { InvoiceDrawer } from "../../components/collections/InvoiceDrawer";
import Link from "antd/es/typography/Link";
const { Text } = Typography;

type OnChange = NonNullable<TableProps<CustomerProps>["onChange"]>;

type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

export const CustomerPage = () => {
  const { idClient } = useParams();
  const navigate = useNavigate();
  const { clientInfo, isDemoEnv, profileInfo } = useContext(AuthContext);
  const { DemoModal, openModalDemo } = useDemoModal();
  const [sortedInfo, setSortedInfo] = useState<Sorts>({});
  const {
    getData: getListData,
    customerOptions,
    isTourOpen,
    customerByClient,
    templateList,
    dataLoading,
    handleTour,
    getCustomersListByClient,
    handleCreateData,
    handleEditData,
    handleBulkEdit,
    handleWarning,
  } = useContext(DataContext);
  const { onDemandReminder, qbLoading } = useContext(QuickbooksContext);
  const [selectedRowsToEdit, setSelectedRowToEdit] = useState<React.Key[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState<{
    open: boolean;
    multiple: boolean;
    payload?: CustomerProps["ReminderSettings"] | null;
  }>({
    open: false,
    multiple: false,
    payload: null,
  });
  const [dataTableSize] = useState<number>(100);
  const [tableParams, setTableParams] = useState<TableParams>({});
  const [customerSelected, setCustomerSelected] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeKey, setActiveKey] = useState("1");
  const [modalStatus, setModalStatus] = useState<{
    open: boolean;
    create: boolean;
    update: boolean;
    multiple: boolean;
    payload?: TemplateProps | null;
  }>(MODAL_INITIAL_STATE);
  const [customerDrawerStatus, setCustomerDrawerStatus] = useState<{
    open: boolean;
    idCustomer: string | null;
  }>({ open: false, idCustomer: "" });
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);

  useEffect(() => {
    if (idClient) {
      getListData("customer", idClient);
      getListData("template", idClient, undefined, { Active: true });
      getCustomersListByClient({ idClient });
    }
  }, [idClient]);

  useEffect(() => {
    setSearchQuery("");
    if (isDemoEnv) {
      let tourOpened = localStorage.getItem("tour-summary");
      if (!tourOpened) {
        handleTour(true);
        localStorage.setItem("tour-customer", "true");
      }
    }
  }, []);

  useEffect(() => {
    const defaultSortKey = profileInfo?.preferences?.collections?.customer
      ?.defaultSortColumnKey || { name: "_id", order: "ascend" };
    setSortedInfo({
      columnKey: defaultSortKey.name,
      order: defaultSortKey.order as "ascend" | "descend",
    });
  }, [profileInfo]);

  useEffect(() => {
    setTableParams({
      pagination: {
        pageSize: dataTableSize,
        total: customerByClient.length,
        onChange: (_, pageSize) => {
          setTableParams((prev) => {
            return {
              ...prev,
              pagination: {
                ...prev.pagination,
                pageSize: pageSize,
              },
            };
          });
        },
      },
    });
  }, [customerByClient]);

  const sendMultipleReminder = () => {
    if (isDemoEnv) {
      openModalDemo();
    } else {
      const selectedCustomers = customerByClient
        .filter((item) => {
          return selectedRowsToEdit.includes(item.Id) && item.Balance > 0;
        })
        .map((item) => item.Id);
      if (clientInfo && selectedCustomers.length > 0) {
        onDemandReminder({
          idClient: clientInfo._id,
          customerList: selectedRowsToEdit as string[],
          next: () => {
            setSelectedRowToEdit([]);
            idClient &&
              getCustomersListByClient({
                idClient,
                filters: {
                  customer:
                    customerSelected.length > 0 ? customerSelected : null,
                },
              });
          },
        });
      } else {
        handleWarning(true, "Cutomer(s) with no balance selected", "Ok", () =>
          setSelectedRowToEdit([])
        );
      }
    }
  };

  const onChangeSelectedRowsToEdit = (newSelectedRowToEdit: React.Key[]) => {
    setSelectedRowToEdit(newSelectedRowToEdit);
  };

  const rowSelectionToEdit = {
    selectedRowKeys: selectedRowsToEdit,
    onChange: onChangeSelectedRowsToEdit,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const handleChange: OnChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter as Sorts);
  };

  const steps: TourProps["steps"] = [
    {
      title: "Toolbar",
      description:
        "This is the toolbar, where you can find all the actions, filters, and export options.",
      target: () => ref1.current,
    },
    {
      title: "Edit Single/Multiple Customer(s)",
      description:
        "Edit the Reminder Settings for one or multiple Customers at the same time.",
      target: () => ref3.current,
    },

    {
      title: "Send Aging Listing",
      description:
        "Send an email listing all due Invoices to the selected customer(s) with overdue invoices.",
      target: () => ref4.current,
    },
    {
      title: "Export Data",
      description: "Export the Customer list data to a CSV file.",
      target: () => ref5.current,
    },
    {
      title: "Create New Template",
      description: "Create a new Template for the Customer Reminder.",
      target: () => ref6.current,
    },
    {
      title: "See All templates",
      description: "See all the Templates created for the Customer Reminder.",
      target: () => ref7.current,
    },
    {
      title: "Filter By Customer Name",
      description: "Filter the Customer list by Customer Name.",
      target: () => ref8.current,
    },
  ];

  const hasSelected = selectedRowsToEdit.length > 0;

  const columns: ColumnsType<CustomerProps> = [
    {
      title: "Client",
      dataIndex: "DisplayName",
      key: "DisplayName",
      className: "table-row",
      render: (_, { DisplayName }) => (
        <Tooltip title="Click to see Customer Info">
          <Link>{DisplayName}</Link>
        </Tooltip>
      ),
      onCell: (record, index) => {
        return {
          onClick: () =>
            setCustomerDrawerStatus({ open: true, idCustomer: record.Id }),
        };
      },
      sorter: {
        compare: (a, b) => a.DisplayName.localeCompare(b.DisplayName),
        multiple: 1,
      },
      sortOrder:
        sortedInfo.columnKey === "DisplayName" ? sortedInfo.order : null,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Balance",
      dataIndex: "Balance",
      key: "Balance",
      width: 100,
      className: "table-row align-center",
      render: (_, { Balance }) => <>{USDollar.format(Balance || 0)}</>,
      sorter: {
        compare: (a, b) => a.Balance - b.Balance,
        multiple: 2,
      },
      sortOrder: sortedInfo.columnKey === "Balance" ? sortedInfo.order : null,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Last Reminder",
      dataIndex: "lastReminderDate",
      key: "lastReminderDate",
      width: 120,
      className: "table-row align-center",
      hidden: templateList.filter((item) => item.Multiple).length === 0,
      render: (_, { ReminderSettings }) => (
        <>
          {ReminderSettings?.lastReminderDate
            ? dayjs(ReminderSettings?.lastReminderDate).format("MM/DD/YYYY")
            : ""}
        </>
      ),
      sorter: (a, b) =>
        a.ReminderSettings?.lastReminderDate
          ? new Date(a.ReminderSettings?.lastReminderDate as any).getTime()
          : 0 -
            (b.ReminderSettings?.lastReminderDate
              ? new Date(b.ReminderSettings?.lastReminderDate as any).getTime()
              : 0),
      sortOrder:
        sortedInfo.columnKey === "lastReminderDate" ? sortedInfo.order : null,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Next Reminder",
      dataIndex: "nextReminderDate",
      key: "nextReminderDate",
      width: 150,
      className: "table-row align-center",
      hidden: templateList.filter((item) => item.Multiple).length === 0,
      render: (_, { ReminderSettings, _id }) => (
        <DatePicker
          name="nextReminderDate"
          format={"MM/DD/YYYY"}
          value={
            ReminderSettings?.nextReminderDate &&
            dayjs(ReminderSettings.nextReminderDate)
          }
          allowClear={false}
          onChange={(date) => {
            if (isDemoEnv) {
              openModalDemo();
            } else {
              if (date) {
                handleEditData({
                  endpoint: `customer/${idClient}/${_id}`,
                  newData: {
                    newValues: { "ReminderSettings.nextReminderDate": date },
                  },
                  next: () => {
                    idClient && getCustomersListByClient({ idClient });
                  },
                });
              }
            }
          }}
        />
      ),
      sorter: (a, b) =>
        a.ReminderSettings?.nextReminderDate
          ? new Date(a.ReminderSettings?.nextReminderDate).getTime()
          : 0 -
            (b.ReminderSettings?.nextReminderDate
              ? new Date(b.ReminderSettings?.nextReminderDate).getTime()
              : 0),
      sortOrder:
        sortedInfo.columnKey === "nextReminderDate" ? sortedInfo.order : null,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Invoice List frequency",
      dataIndex: "frequency",
      key: "frequency",
      className: "table-row align-center",
      width: 180,
      hidden: templateList.filter((item) => item.Multiple).length === 0,
      render: (_, { ReminderSettings, _id }) => (
        <Select
          style={{ width: "100%", maxWidth: "150px" }}
          showSearch
          optionFilterProp="children"
          value={ReminderSettings?.frequency}
          onChange={(value) => {
            if (isDemoEnv) {
              openModalDemo();
            } else {
              if (value) {
                handleEditData({
                  endpoint: `customer/${idClient}/${_id}`,
                  newData: {
                    newValues: { "ReminderSettings.frequency": value },
                  },
                  next: () => {
                    idClient && getCustomersListByClient({ idClient });
                  },
                });
              }
            }
          }}
          options={[
            {
              label: "Daily",
              value: "Daily",
            },
            {
              label: "Weekly",
              value: "Weekly",
            },
            {
              label: "Monthly",
              value: "Monthly",
            },
            {
              label: "Never",
              value: "Never",
            },
            {
              label: "Manual",
              value: "OnDemand",
            },
          ]}
        />
      ),
      sortOrder: sortedInfo.columnKey === "message" ? sortedInfo.order : null,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Automatic Aging",
      dataIndex: "frequency",
      key: "frequency",
      width: 150,
      className: "table-row align-center",
      hidden: templateList.filter((item) => item.Initial).length === 0,
      render: (_, { ReminderSettings, _id }) => (
        <Select
          style={{ width: "100%", maxWidth: "150px" }}
          showSearch
          optionFilterProp="children"
          value={ReminderSettings?.allowAgingReminder}
          onChange={(value) => {
            if (isDemoEnv) {
              openModalDemo();
            } else {
              if (value) {
                handleEditData({
                  endpoint: `customer/${idClient}/${_id}`,
                  newData: {
                    newValues: { "ReminderSettings.allowAgingReminder": value },
                  },
                  next: () => {
                    idClient && getCustomersListByClient({ idClient });
                  },
                });
              }
            }
          }}
          options={[
            {
              label: "ON",
              value: "Yes",
            },
            {
              label: "OFF",
              value: "No",
            },
          ]}
        />
      ),
      sortOrder: sortedInfo.columnKey === "message" ? sortedInfo.order : null,
      ellipsis: {
        showTitle: false,
      },
    },
  ];

  const columnsByAging: ColumnsType<CustomerProps> = [
    {
      title: "Client",
      dataIndex: "DisplayName",
      key: "DisplayName",
      className: "table-row",
      render: (_, { DisplayName }) => (
        <Tooltip title="Click to see Customer Info">
          <Link>{DisplayName}</Link>
        </Tooltip>
      ),
      onCell: (record, index) => {
        return {
          onClick: () =>
            setCustomerDrawerStatus({ open: true, idCustomer: record.Id }),
        };
      },
      sorter: {
        compare: (a, b) => a.DisplayName.localeCompare(b.DisplayName),
        multiple: 1,
      },
      sortOrder:
        sortedInfo.columnKey === "DisplayName" ? sortedInfo.order : null,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Listing Template",
      dataIndex: "templateMultiple",
      key: "templateMultiple",
      width: 150,
      className: "table-row align-center",
      hidden: templateList.filter((item) => item.Multiple).length === 0,
      render: (_, { ReminderSettings, _id }) => (
        <Select
          style={{ width: "100%", maxWidth: "150px" }}
          showSearch
          optionFilterProp="children"
          value={ReminderSettings?.templateMultiple?._id}
          onChange={(value) => {
            if (isDemoEnv) {
              openModalDemo();
            } else {
              if (value) {
                handleEditData({
                  endpoint: `customer/${idClient}/${_id}`,
                  newData: {
                    newValues: { "ReminderSettings.templateMultiple": value },
                  },
                  next: () => {
                    idClient && getCustomersListByClient({ idClient });
                  },
                });
              }
            }
          }}
          options={templateList
            .filter((item) => item.Multiple)
            .map((item) => ({
              label: item.TemplateName,
              value: item._id,
            }))}
        />
      ),
      sortOrder:
        sortedInfo.columnKey === "templateMultiple" ? sortedInfo.order : null,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Default Template",
      dataIndex: "templateInitial",
      key: "templateInitial",
      width: 150,
      className: "table-row align-center",
      hidden: templateList.filter((item) => item.Initial).length === 0,
      render: (_, { ReminderSettings, _id }) => (
        <Select
          style={{ width: "100%", maxWidth: "150px" }}
          showSearch
          optionFilterProp="children"
          value={ReminderSettings?.templateInitial?._id}
          onChange={(value) => {
            if (isDemoEnv) {
              openModalDemo();
            } else {
              if (value) {
                handleEditData({
                  endpoint: `customer/${idClient}/${_id}`,
                  newData: {
                    newValues: { "ReminderSettings.templateInitial": value },
                  },
                  next: () => {
                    idClient && getCustomersListByClient({ idClient });
                  },
                });
              }
            }
          }}
          options={templateList
            .filter((item) => item.Initial)
            .map((item) => ({
              label: item.TemplateName,
              value: item._id,
            }))}
        />
      ),
      sortOrder:
        sortedInfo.columnKey === "templateInitial" ? sortedInfo.order : null,
    },
    {
      title: "15+",
      dataIndex: "template_15",
      width: 100,
      key: "template_15",
      hidden: templateList.filter((item) => item.template_15).length === 0,
      className: "table-row align-center",
      render: (_, { ReminderSettings, _id }) => (
        <Select
          style={{ width: "100%", maxWidth: "150px" }}
          showSearch
          allowClear
          optionFilterProp="children"
          value={ReminderSettings?.template_15?._id}
          onChange={(value) => {
            if (isDemoEnv) {
              openModalDemo();
            } else {
              handleEditData({
                endpoint: `customer/${idClient}/${_id}`,
                newData: {
                  newValues: { "ReminderSettings.template_15": value || null },
                },
                next: () => {
                  idClient && getCustomersListByClient({ idClient });
                },
              });
            }
          }}
          options={templateList
            .filter((item) => item.template_15)
            .map((item) => ({
              label: item.TemplateName,
              value: item._id,
            }))}
        />
      ),
      sortOrder:
        sortedInfo.columnKey === "template_15" ? sortedInfo.order : null,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "30+",
      dataIndex: "template_30",
      width: 100,
      key: "template_30",
      className: "table-row align-center",
      hidden: templateList.filter((item) => item.template_30).length === 0,
      render: (_, { ReminderSettings, _id }) => (
        <Select
          style={{ width: "100%", maxWidth: "150px" }}
          showSearch
          allowClear
          optionFilterProp="children"
          value={ReminderSettings?.template_30?._id}
          onChange={(value) => {
            if (isDemoEnv) {
              openModalDemo();
            } else {
              handleEditData({
                endpoint: `customer/${idClient}/${_id}`,
                newData: {
                  newValues: { "ReminderSettings.template_30": value || null },
                },
                next: () => {
                  idClient && getCustomersListByClient({ idClient });
                },
              });
            }
          }}
          options={templateList
            .filter((item) => item.template_30)
            .map((item) => ({
              label: item.TemplateName,
              value: item._id,
            }))}
        />
      ),
      sortOrder:
        sortedInfo.columnKey === "template_30" ? sortedInfo.order : null,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "45+",
      dataIndex: "template_45",
      key: "template_45",
      width: 100,
      className: "table-row align-center",
      hidden: templateList.filter((item) => item.template_45).length === 0,
      render: (_, { ReminderSettings, _id }) => (
        <Select
          style={{ width: "100%", maxWidth: "150px" }}
          showSearch
          allowClear
          optionFilterProp="children"
          value={ReminderSettings?.template_45?._id}
          onChange={(value) => {
            if (isDemoEnv) {
              openModalDemo();
            } else {
              handleEditData({
                endpoint: `customer/${idClient}/${_id}`,
                newData: {
                  newValues: {
                    "ReminderSettings.template_45": value || null,
                  },
                },
                next: () => {
                  idClient && getCustomersListByClient({ idClient });
                },
              });
            }
          }}
          options={templateList
            .filter((item) => item.template_45)
            .map((item) => ({
              label: item.TemplateName,
              value: item._id,
            }))}
        />
      ),
      sortOrder:
        sortedInfo.columnKey === "template_45" ? sortedInfo.order : null,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "60+",
      dataIndex: "template_60",
      key: "template_60",
      width: 100,
      className: "table-row align-center",
      hidden: templateList.filter((item) => item.template_60).length === 0,
      render: (_, { ReminderSettings, _id }) => (
        <Select
          style={{ width: "100%", maxWidth: "150px" }}
          showSearch
          allowClear
          optionFilterProp="children"
          value={ReminderSettings?.template_60?._id}
          onChange={(value) => {
            if (isDemoEnv) {
              openModalDemo();
            } else {
              handleEditData({
                endpoint: `customer/${idClient}/${_id}`,
                newData: {
                  newValues: {
                    "ReminderSettings.template_60": value || null,
                  },
                },
                next: () => {
                  idClient && getCustomersListByClient({ idClient });
                },
              });
            }
          }}
          options={templateList
            .filter((item) => item.template_60)
            .map((item) => ({
              label: item.TemplateName,
              value: item._id,
            }))}
        />
      ),
      sortOrder:
        sortedInfo.columnKey === "template_60" ? sortedInfo.order : null,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "75+",
      dataIndex: "template_75",
      key: "template_75",
      width: 100,
      className: "table-row align-center",
      hidden: templateList.filter((item) => item.template_75).length === 0,
      render: (_, { ReminderSettings, _id }) => (
        <Select
          style={{ width: "100%", maxWidth: "150px" }}
          showSearch
          allowClear
          optionFilterProp="children"
          value={ReminderSettings?.template_75?._id}
          onChange={(value) => {
            if (isDemoEnv) {
              openModalDemo();
            } else {
              handleEditData({
                endpoint: `customer/${idClient}/${_id}`,
                newData: {
                  newValues: {
                    "ReminderSettings.template_75": value || null,
                  },
                },
                next: () => {
                  idClient && getCustomersListByClient({ idClient });
                },
              });
            }
          }}
          options={templateList
            .filter((item) => item.template_75)
            .map((item) => ({
              label: item.TemplateName,
              value: item._id,
            }))}
        />
      ),
      sortOrder:
        sortedInfo.columnKey === "template_75" ? sortedInfo.order : null,
      ellipsis: {
        showTitle: false,
      },
    },

    {
      title: "90+",
      dataIndex: "template_90",
      key: "template_90",
      width: 100,
      className: "table-row align-center",
      hidden: templateList.filter((item) => item.template_90).length === 0,
      render: (_, { ReminderSettings, _id }) => (
        <Select
          style={{ width: "100%" }}
          showSearch
          allowClear
          optionFilterProp="children"
          value={ReminderSettings?.template_90?._id}
          onChange={(value) => {
            if (isDemoEnv) {
              openModalDemo();
            } else {
              handleEditData({
                endpoint: `customer/${idClient}/${_id}`,
                newData: {
                  newValues: {
                    "ReminderSettings.template_90": value || null,
                  },
                },
                next: () => {
                  idClient && getCustomersListByClient({ idClient });
                },
              });
            }
          }}
          options={templateList
            .filter((item) => item.template_90)
            .map((item) => ({
              label: item.TemplateName,
              value: item._id,
            }))}
        />
      ),
      sortOrder:
        sortedInfo.columnKey === "template_90" ? sortedInfo.order : null,
      ellipsis: {
        showTitle: false,
      },
    },
  ];

  const CardCreateTemplate = () => (
    <Card
      title="No Templates Found"
      className="summary-client__card"
      extra={
        <Tooltip title="Create New Template">
          <Button
            icon={<FolderAddOutlined />}
            type="link"
            onClick={() => {
              setModalStatus({
                open: true,
                create: true,
                update: false,
                multiple: false,
                payload: {
                  ...TEMPLATE_INITIAL_STATE,
                  Initial: true,
                  Default: false,
                },
              });
            }}
          ></Button>
        </Tooltip>
      }
      actions={[
        <Button
          type="link"
          onClick={() => {
            setModalStatus({
              open: true,
              create: true,
              update: false,
              multiple: false,
              payload: {
                ...TEMPLATE_INITIAL_STATE,
                Initial: true,
                Default: false,
              },
            });
          }}
        >
          Create New Template
        </Button>,
      ]}
    >
      <p>No Templates found</p>
    </Card>
  );

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Set Frequency",
      children: (
        <>
          {templateList.length === 0 ? (
            CardCreateTemplate()
          ) : (
            <Table
              bordered
              columns={columns}
              onChange={handleChange}
              rowSelection={rowSelectionToEdit}
              dataSource={customerByClient}
              className="summary-client__table"
              pagination={tableParams.pagination}
              size="small"
              rowKey={"Id"}
              scroll={{ y: "70vh", x: "100%" }}
              tableLayout="auto"
            />
          )}
        </>
      ),
    },
    {
      key: "2",
      label: "Set Templates",
      children: (
        <>
          {templateList.length === 0 ? (
            CardCreateTemplate()
          ) : (
            <Table
              bordered
              columns={columnsByAging}
              onChange={handleChange}
              rowSelection={rowSelectionToEdit}
              dataSource={customerByClient}
              className="summary-client__table"
              pagination={tableParams.pagination}
              size="small"
              rowKey={"Id"}
              scroll={{ y: "70vh", x: "100%" }}
              tableLayout="auto"
            />
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <CardTitle title={clientInfo?.name + " - Customer List"} />
        </ColumnComponent>
        <ColumnComponent>
          <div ref={ref1}>
            <CardComponent
              className="summary-client__card"
              title={"Reminders"}
              extra={
                <SearchInput
                  onSearch={(value: string) => {
                    if (value) {
                      setSearchQuery(value);
                      idClient &&
                        getCustomersListByClient({
                          idClient,
                          search: value,
                        });
                    } else {
                      setSearchQuery("");
                      idClient &&
                        getCustomersListByClient({
                          idClient,
                        });
                    }
                  }}
                />
              }
              actions={[
                <div ref={ref3}>
                  <Tooltip title="Edit" placement="left">
                    <Button
                      type="link"
                      icon={<EditOutlined />}
                      disabled={!hasSelected}
                      size="large"
                      onClick={() =>
                        setIsEditModalOpen({
                          open: true,
                          multiple: activeKey === "1",
                          payload:
                            selectedRowsToEdit.length === 1
                              ? customerByClient.find(
                                  (customer) =>
                                    customer.Id === selectedRowsToEdit[0]
                                )?.ReminderSettings || null
                              : null,
                        })
                      }
                    />
                  </Tooltip>
                </div>,
                <div ref={ref4}>
                  <Tooltip title="Send Aging Listing Now" placement="left">
                    <Popconfirm
                      title="Send Reminders for selected Customer(s)?"
                      onConfirm={sendMultipleReminder}
                    >
                      <Button
                        loading={qbLoading}
                        type="link"
                        disabled={selectedRowsToEdit.length === 0}
                        icon={<MailOutlined />}
                        size="large"
                      />
                    </Popconfirm>
                  </Tooltip>
                </div>,
                <div ref={ref5}>
                  <Tooltip
                    title={
                      selectedRowsToEdit.length > 0
                        ? "Export Selected Data"
                        : "Export Data"
                    }
                    placement="left"
                  >
                    <Button
                      type="link"
                      icon={<DownloadOutlined />}
                      size="large"
                      onClick={() => {
                        exportCustomerFile(
                          customerByClient,
                          selectedRowsToEdit as string[]
                        );
                      }}
                    />
                  </Tooltip>
                </div>,
                <div ref={ref6}>
                  <Tooltip title="Create new Template" placement="left">
                    <Button
                      type="link"
                      icon={<FolderAddOutlined />}
                      size="large"
                      onClick={() =>
                        setModalStatus({
                          open: true,
                          create: true,
                          update: false,
                          multiple: false,
                          payload: {
                            ...TEMPLATE_INITIAL_STATE,
                            Initial: true,
                            Default: false,
                          },
                        })
                      }
                    />
                  </Tooltip>
                </div>,
                <div ref={ref7}>
                  <Tooltip title="See all Templates" placement="left">
                    <Button
                      type="link"
                      icon={<FolderOpenOutlined />}
                      size="large"
                      onClick={() => navigate(`/template/${idClient}`)}
                    />
                  </Tooltip>
                </div>,
                <div ref={ref8}>
                  <Tooltip title="Filter By Customer">
                    <MultipleSelect
                      placeholder="Customer"
                      className="summary-client__card__actions__select"
                      options={customerOptions}
                      onSelect={(values) => {
                        setCustomerSelected(values as any);
                        let query = values.length > 0 ? values : null;
                        idClient &&
                          getCustomersListByClient({
                            idClient,
                            filters: { customer: query },
                          });
                      }}
                    />
                  </Tooltip>
                </div>,
              ]}
            ></CardComponent>
          </div>
        </ColumnComponent>
        <ColumnComponent>
          <Tabs
            defaultActiveKey="1"
            items={items}
            onTabClick={(key) => {
              setActiveKey(key);
            }}
          ></Tabs>
        </ColumnComponent>
        <ColumnComponent>
          <div style={{ textAlign: "right" }}>
            <Tooltip title="Help">
              <Button
                icon={<QuestionCircleOutlined style={{ color: "#17549a" }} />}
                type="default"
                onClick={() => {
                  handleTour(true);
                }}
              />
            </Tooltip>
          </div>
        </ColumnComponent>
      </Row>
      <ModalCustomerBulkUpdate
        title="Edit Reminder Settings for Selected Customer(s)"
        isModalOpen={isEditModalOpen}
        onSubmit={(values) => {
          if (isDemoEnv) {
            openModalDemo();
          } else {
            clientInfo &&
              idClient &&
              handleBulkEdit({
                endpoint: `customer-bulk/${idClient}`,
                items: selectedRowsToEdit as string[],
                newData: {
                  newValues: {
                    "ReminderSettings.frequency": values.frequency,
                    "ReminderSettings.templateMultiple":
                      values.templateMultiple,
                    "ReminderSettings.allowAgingReminder":
                      values.allowAgingReminder || null,
                    "ReminderSettings.templateInitial": values.templateInitial,
                    "ReminderSettings.template_15": values.template_15 || null,
                    "ReminderSettings.template_30": values.template_30 || null,
                    "ReminderSettings.template_45": values.template_45 || null,
                    "ReminderSettings.template_60": values.template_60 || null,
                    "ReminderSettings.template_75": values.template_75 || null,
                    "ReminderSettings.template_90": values.template_90 || null,
                    "ReminderSettings.nextReminderDate":
                      values.nextReminderDate,
                  },
                },

                next: () =>
                  getCustomersListByClient({
                    idClient,
                    filters: {
                      customer:
                        customerSelected.length > 0 ? customerSelected : null,
                    },
                    next: () => {
                      setIsEditModalOpen({
                        open: false,
                        multiple: activeKey === "1",
                      });
                    },
                  }),
              });
          }
        }}
        onCancel={() => {
          setIsEditModalOpen({
            open: false,
            multiple: activeKey === "1",
          });
        }}
      />
      <ModalTemplateCreateOrUpdate
        title="Create/Update Template"
        modalStatus={modalStatus}
        onSubmit={(values) => {
          if (isDemoEnv) {
            openModalDemo();
          } else {
            if (clientInfo && idClient) {
              handleCreateData({
                endpoint: "template",
                idClient,
                data: values,
                next: () => {
                  getCustomersListByClient({
                    idClient,
                    filters: { customer: customerSelected },
                  });
                  getListData("template", idClient, undefined, {
                    Active: true,
                  });
                  getCustomersListByClient({ idClient });
                },
              });
              setModalStatus(MODAL_INITIAL_STATE);
            }
          }
        }}
        onCancel={() => {
          setModalStatus(MODAL_INITIAL_STATE);
        }}
      />
      {idClient && (
        <CustomerDrawer
          open={customerDrawerStatus.open}
          idClient={idClient}
          idCustomer={customerDrawerStatus.idCustomer}
          onClose={() =>
            setCustomerDrawerStatus({ open: false, idCustomer: "" })
          }
        />
      )}
      <Tour open={isTourOpen} onClose={() => handleTour(false)} steps={steps} />
      <DemoModal />
    </>
  );
};
