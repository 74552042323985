import { Modal } from "antd";
import NotificationContext from "./NotificationContext";
import { useContext } from "react";

type NotificationType = "success" | "error" | "info" | "warning";

type NotificationContextType = {
  showNotification: (
    type: NotificationType,
    title: string,
    message: string,
    duration?: number
  ) => void;
};

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [modal, contextHolder] = Modal.useModal();

  const showNotification = (
    type: NotificationType,
    title: string,
    message: string,
    duration: number = 5
  ) => {
    let secondsToGo = duration;

    const instance = modal[type]({
      title,
      content: message,
    });

    const timer = setInterval(() => {
      secondsToGo -= 1;
      instance.update({
        content: message,
      });
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      instance.destroy();
    }, secondsToGo * 1000);
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = (): NotificationContextType => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};
