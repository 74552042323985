import React, { useContext, useEffect } from "react";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import {
  DashboardOutlined,
  SettingOutlined,
  DollarOutlined,
  LogoutOutlined,
  FileDoneOutlined,
  QuestionCircleOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import AuthContext from "../../context/AuthContext";
import DataContext from "../../context/DataContext";

const Navbar = () => {
  const navigate = useNavigate();
  const { onLogout, clientIdSelected, role } = useContext(AuthContext);
  const { handleTour } = useContext(DataContext);

  const menuItems: MenuProps["items"] = [
    {
      label: "Dashboard",
      key: `/dashboard/${clientIdSelected}`,
      icon: <DashboardOutlined className="icon" />,
    },
    {
      label: "A/R Agings",
      key: "aging",
      icon: <DollarOutlined className="icon" />,
      children: [
        {
          label: "Summary",
          key: `/summary/${clientIdSelected}`,
        },
        {
          label: "Details",
          key: `/detail/${clientIdSelected}`,
        },
      ],
    },
    {
      label: "Reminders",
      key: "reminder",
      icon: <AuditOutlined className="icon" />,
      children: [
        {
          label: "by Customer",
          key: `/customer/${clientIdSelected}`,
        },
        {
          label: "Templates",
          key: `/template/${clientIdSelected}`,
        },
      ],
    },
    {
      label: "Reports",
      key: `/report/${clientIdSelected}`,
      icon: <FileDoneOutlined className="icon" />,
    },
    { type: "divider" },
    {
      label: "Settings",
      key: `/settings/${clientIdSelected}`,
      icon: <SettingOutlined className="icon" />,
      children: [
        {
          label: "Transactions",
          key: `delete`,
          children: [
            {
              label: "Invoices",
              key: `/invoice/${clientIdSelected}`,
            },
            {
              label: "Credits",
              key: `/credit/${clientIdSelected}`,
            },
            {
              label: "Payments",
              key: `/payment/${clientIdSelected}`,
            },
            {
              label: "Deposits",
              key: `/deposit/${clientIdSelected}`,
            },
            {
              label: "Journal Entries",
              key: `/journal/${clientIdSelected}`,
            },
          ],
        },
        {
          label: "Add/Edit Status",
          key: `/status/${clientIdSelected}`,
        },
        {
          label: "Company",
          key: `/company/${clientIdSelected}`,
        },
        {
          label: "Support",
          key: "/help",
        },
        {
          label: "QuickBooks",
          key: `/quickbooks/${clientIdSelected}`,
          style:
            role === "CLIENT" || role === "DEMO" ? { display: "none" } : {},
        },
      ],
    },
    {
      label: "Help",
      key: "help",
      icon: <QuestionCircleOutlined />,
    },
    {
      label: "Logout",
      key: "logout",
      icon: <LogoutOutlined />,
    },
  ];

  const adminOptions: MenuProps["items"] = [
    // {
    //   label: "Admin",
    //   key: "admin",
    //   icon: <DashboardOutlined className="icon" />,
    //   children: [
    //     {
    //       label: "Users",
    //       key: `/users/${clientIdSelected}`,
    //     },
    //   ],
    // },
  ];

  const onClick: MenuProps["onClick"] = ({ key }) => {
    if (key === "logout") {
      onLogout(() => navigate("/login"));
    } else if (key === "help") {
      handleTour(true);
    } else {
      navigate(key);
    }
  };

  return (
    <>
      <Menu
        className="pt-2"
        onClick={onClick}
        defaultSelectedKeys={["1"]}
        mode="vertical"
        items={role === "ADMIN" ? [...adminOptions, ...menuItems] : menuItems}
      />
    </>
  );
};

export default Navbar;
