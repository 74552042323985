import React from "react";

type NotificationType = "success" | "error" | "info" | "warning";

type NotificationContextType = {
  showNotification: (
    type: NotificationType,
    title: string,
    message: string,
    duration?: number
  ) => void;
};

const NotificationContext = React.createContext<
  NotificationContextType | undefined
>(undefined);

export default NotificationContext;
