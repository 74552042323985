import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import { UserProps } from "../../interfaces/interfaces";
import { USER_INITIAL_STATE } from "../../utils/data";
// const { Option } = Select;

interface Props {
  modalStatus: {
    open: boolean;
    create: boolean;
    update: boolean;
    multiple: boolean;
    payload?: UserProps | null;
  };
  onCancel: () => void;
  onSubmit: (values: UserProps) => void;
}

// const roleOptions = [
//   {
//     label: "Admin",
//     value: "ADMIN",
//   },
//   {
//     label: "User",
//     value: "USER",
//   },
//   // {
//   //   label: "Client",
//   //   value: "CLIENT",
//   // },
// ];

export const ModalUserForm = ({
  modalStatus: { payload, open, create },
  onCancel,
  onSubmit,
}: Props) => {
  const [form] = Form.useForm();
  const [values, setValues] = useState<UserProps>(USER_INITIAL_STATE);
  const onFinish = (values: any) => {
    onSubmit(values);
  };

  useEffect(() => {
    if (values) {
      form.setFieldsValue(values);
    } else {
      form.setFieldsValue(USER_INITIAL_STATE);
      setValues(USER_INITIAL_STATE);
    }
  }, [values]);

  useEffect(() => {
    if (payload) {
      setValues(payload);
    } else {
      setValues(USER_INITIAL_STATE);
    }
  }, [payload]);

  return (
    <Modal
      onOk={onCancel}
      onCancel={onCancel}
      footer={null}
      open={open}
      title="User"
    >
      <Form
        form={form}
        initialValues={values}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input the name" }]}
        >
          <Input
            value={values?.name}
            onChange={(event) =>
              setValues((prev) => {
                return {
                  ...prev,
                  name: event.target.value,
                };
              })
            }
          />
        </Form.Item>
        {create && (
          <>
            <Form.Item
              name="username"
              label="Email"
              rules={[
                { required: true, message: "Please input the email" },
                { type: "email", message: "Please input a valid email" },
              ]}
            >
              <Input
                value={values?.username}
                onChange={(event) =>
                  setValues((prev) => {
                    return {
                      ...prev,
                      username: event.target.value,
                    };
                  })
                }
              />
            </Form.Item>
            <Form.Item
              name="username2"
              label="Confirm Email"
              className="collections-form__form-item"
              rules={[
                {
                  required: true,
                  message: "Please confirm the email",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("username") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The  email that you entered do not match!")
                    );
                  },
                }),
              ]}
            >
              <Input
                placeholder="Confirm email Address"
                value={values?.username2}
                onChange={(event) =>
                  setValues((prev) => {
                    return {
                      ...prev,
                      username2: event.target.value,
                    };
                  })
                }
              />
            </Form.Item>
          </>
        )}

        {/* <Form.Item
          name="role.permissions"
          label="Role"
          rules={[{ required: true, message: "Please select the role" }]}
        >
          <Select
            value={payload?.role?.permissions}
            showSearch
            placeholder="Select a role"
            options={roleOptions}
            disabled={payload?.role?.permissions === "ADMIN"}
          />
        </Form.Item> */}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
